import { Middleware } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { fetchMessages, postMessage } from './messages/messages.thunks';
import { fetchMessageRooms } from './messageRooms/messageRooms.thunks';

export const autoFetchMessagesMiddleware: Middleware<{}, RootState> = store => next => action => {
  next(action);

  if (postMessage.fulfilled.match(action)) {
    const roomId = action.meta.arg.roomId || '';
    const moveId = action.meta.arg.moveId || '';

    if (!!roomId && !!moveId) {
      store.dispatch(
        // @ts-ignore
        fetchMessages({
          roomId: roomId as string,
          moveId,
          newMessageExpected: true,
        })
      );

      store.dispatch(
        // @ts-ignore
        fetchMessageRooms(moveId)
      );
    }
  }
};
