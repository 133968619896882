// Components
import { ConditionalWrapper } from '@components/ConditionalWrapper';

// Contexts
import { useModals } from '@contexts/modalContext';

// Images
import { ReactComponent as CoadjuteSVG } from '@images/connected-by-coadjute.svg';
import { Alert } from '@images/icons';

type CoadjutePillProps = {
  clear?: boolean;
  showPill?: boolean;
};

export function CoadjutePill({ showPill }: CoadjutePillProps) {
  const modals = useModals();

  return (
    <ConditionalWrapper
      condition={!!showPill}
      wrapper={children => (
        <div className="inline-block rounded-full bg-white px-5 py-3">{children}</div>
      )}
    >
      <div className="flex w-fit max-w-full flex-row items-center gap-3 rounded-full bg-transparent pr-3.5">
        <CoadjuteSVG className="mt-0.5" aria-label="Coadjute Logo" />
        <Alert
          className="h-5 w-5 cursor-pointer fill-primary"
          aria-label="Show Coadjute Information"
          onClick={() => modals.openModal('coadjuteXHome')}
          tabIndex={0}
        />
      </div>
    </ConditionalWrapper>
  );
}
