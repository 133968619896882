import { HomeCoadjuteModal } from '@components/Modals/HomeCoadjuteModal';
import { MovingDay } from '@components/Modals/MovingDay';
import { ReactNode, createContext, useContext, useReducer } from 'react';

type ModalState = {
  coadjuteXHome: boolean;
  movingDay: boolean;
};

type ModalAction = {
  type: 'SET_MODAL_STATE';
  payload: { modalName: keyof ModalState; isOpen: boolean };
};

const initialState: ModalState = {
  coadjuteXHome: false,
  movingDay: false,
};

const modalContext = createContext({
  state: initialState,
  openModal: (modalName: keyof ModalState) => {},
  closeModal: (modalName: keyof ModalState) => {},
});

const modalReducer = (state: ModalState, action: ModalAction) => {
  switch (action.type) {
    case 'SET_MODAL_STATE':
      return { ...state, [action.payload.modalName]: action.payload.isOpen };
    default:
      return state;
  }
};

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);

  const openModal = (modalName: keyof ModalState) => {
    dispatch({ type: 'SET_MODAL_STATE', payload: { modalName, isOpen: true } });
  };

  const closeModal = (modalName: keyof ModalState) => {
    dispatch({ type: 'SET_MODAL_STATE', payload: { modalName, isOpen: false } });
  };

  return (
    <modalContext.Provider value={{ state, openModal, closeModal }}>
      {state.coadjuteXHome && <HomeCoadjuteModal />}
      {state.movingDay && <MovingDay />}
      {children}
    </modalContext.Provider>
  );
};

export const useModals = () => useContext(modalContext);
