// Types
import { Task } from '@features/moveStages/moveStages.types';

// Local
import { getIcon } from '.';

export const AutoTask = ({ status, uiName }: Task) => {
  const getTooltipText = (status: string) => {
    switch (status) {
      case 'COMPLETED':
        return 'Completed';
      case 'IN_PROGRESS':
        return 'In progress';
      case 'NOT_STARTED':
      default:
        return 'Not started';
    }
  };

  return (
    <div
      className="flex items-center gap-3"
      data-tooltip-id="task-tooltip"
      data-tooltip-content={getTooltipText(status)}
      data-tooltip-place="bottom"
    >
      <span className="flex w-10 items-center justify-center">{getIcon(status)}</span>
      <span
        className={`${
          status === 'NOT_STARTED' ? 'text-gray-200 md:text-gray-500' : 'text-white md:text-primary'
        }`}
      >
        {uiName}
      </span>
    </div>
  );
};
