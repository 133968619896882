import React, { useState } from 'react';

type Tab = {
  label: string;
  content: React.ReactNode;
};

type TabsProps = {
  tabs: Tab[];
  fullWidth?: boolean;
};

export const Tabs = ({ tabs, fullWidth }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="flex flex-col gap-10">
      <div className="flex justify-center gap-8">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`cursor-pointer border-b-2 p-3 text-center text-sm font-medium uppercase ${
              fullWidth && 'w-full'
            } ${activeTab === index ? 'border-primary' : 'border-transparent'}`}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div>
        {tabs.map((tab, index) => index === activeTab && <div key={index}>{tab.content}</div>)}
      </div>
    </div>
  );
};
