import { Tabs } from '@components/Tabs';
import { PRODUCT_CATEGORIES, Product } from './productList';
import { LinkButton } from '@components/Button';
import { NonNetworkServices, Services } from './services';

const Products = ({ products }: { products: Product[] }) => {
  const hero = products[0];
  const rest = products.slice(1);

  return (
    <div className="mx-auto grid max-w-md grid-cols-1 gap-5 md:max-w-max lg:grid-cols-2">
      <div className="flex flex-col gap-6 border border-gray-200 bg-white pb-8 shadow-homeLarge">
        <div
          className="w-full bg-cover bg-center bg-no-repeat pb-[62%] md:pb-[52%]"
          style={{ backgroundImage: `url(${hero.image})` }}
        />
        <div className="flex h-full flex-col items-center justify-center gap-5 px-4">
          <h3 className="text-center text-2xl font-medium">{hero.title}</h3>
          <p className="text-center">{hero.text}</p>
          <a href={hero.link} className="rounded-full bg-primary px-5 py-2 text-base text-white">
            Buy now
          </a>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        {rest.map(product => (
          <div
            key={product.title}
            className="relative flex flex-col gap-4 border border-gray-200 bg-white pb-5 shadow-homeLarge"
          >
            {product.offerText && (
              <span className="absolute -top-2 left-2 rounded-full bg-primary px-2 py-1 text-xs font-medium text-white">
                {product.offerText}
              </span>
            )}
            <div
              className="w-full bg-cover bg-center bg-no-repeat pb-[62%]"
              style={{ backgroundImage: `url(${product.image})` }}
            />
            <div className="flex flex-col items-center gap-3 px-4">
              <h3 className="text-center text-xl font-medium">{product.title}</h3>
              <div className="flex w-full items-center justify-between gap-2">
                {product.price && <span>£{product.price}</span>}
                <a
                  href={product.link}
                  target="_blank"
                  className="rounded-full bg-primary px-5 py-2 text-sm text-white"
                  rel="noreferrer"
                >
                  Buy now
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

type StoreProps = {
  isMoving: boolean;
  isBuying: boolean;
  isNetworkUser?: boolean;
};

export const Store = (props: StoreProps) => {
  return (
    <div className="mx-auto flex w-full max-w-3xl flex-col gap-8 px-5 pt-24 lg:max-w-[1260px]">
      <h3 className="text-center text-4xl font-medium">Your home in your hand</h3>
      <p className="mx-auto max-w-2xl text-center">
        Access to everything you need to make it home.
      </p>

      <Tabs
        tabs={[
          {
            label: 'Services',
            content: props.isNetworkUser ? <Services {...props} /> : <NonNetworkServices />,
          },
          ...PRODUCT_CATEGORIES.map(product => ({
            label: product.name,
            content: <Products products={product.items} />,
          })),
        ]}
      />

      <div className="flex w-full justify-center">
        <LinkButton href="https://store.home.cc/" target="_blank">
          Explore more
        </LinkButton>
      </div>
    </div>
  );
};
