import { Chat, Envelope, Phone, WhatsApp } from '@images/icons';

export const HOMERUNNER_CONTACT_DETAILS = [
  {
    icon: Chat,
    name: 'Live Chat',
    link: {
      href: 'https://direct.lc.chat/14763165/',
      text: 'Start chatting',
    },
  },
  {
    icon: Phone,
    name: 'Phone',
    link: {
      href: 'tel:03300433828',
      text: '03300 433 828',
    },
  },
  {
    icon: Envelope,
    name: 'Email',
    link: {
      href: 'mailto:team@home.cc',
      text: 'team@home.cc',
    },
  },
  {
    icon: WhatsApp,
    name: 'WhatsApp',
    link: {
      href: 'https://wa.me/447535742914',
      text: '+44 7535 742914',
    },
  },
];
