import React from 'react';

type ChipProps = {
  children: React.ReactNode;
  icon: React.ReactNode;
};

export const Chip = ({ children, icon }: ChipProps) => {
  const iconWithStyles = React.cloneElement(icon as React.ReactElement, {
    className: 'fill-white h-6 w-6',
  });
  return (
    <span className="flex items-center gap-1 rounded-full bg-primary px-2 py-1 text-xs text-white">
      {iconWithStyles}
      {children}
    </span>
  );
};
