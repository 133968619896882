// Global
import { Link } from 'react-router-dom';

// Components
import { CoadjutePill } from '@components/CoadjutePill';

// Icons
import { Chat, Envelope, Phone, WhatsApp } from '@images/icons';
import { ConditionalWrapper } from '@components/ConditionalWrapper';

// Utils
import { convertINameToString } from '@utils/convertINameToString';
import { Agencies, getAgencyStyling } from '@utils/agencyStyling';

type ContactCardProps = {
  cardTitle?: string;
  chipText?: string;
  agency: IAgency;
  type?: string;
  onMessagingClick?: () => void;
  testid?: string;
  hideCoadjutePill?: boolean;
};

export const ContactCard = ({
  cardTitle,
  agency,
  chipText,
  onMessagingClick,
  type,
  testid = '',
  hideCoadjutePill,
}: ContactCardProps) => {
  const agencyStyle = getAgencyStyling(agency.code);

  const messagesLink = `/messages${!!type && `?id=${type.toLowerCase()}`}`;

  return (
    <ConditionalWrapper
      condition={!!cardTitle}
      wrapper={children => (
        <div className="flex w-full flex-col items-center gap-8 md:items-start">
          <h3 className="text-center text-4xl font-medium text-white md:text-left">{cardTitle}</h3>
          {children}
        </div>
      )}
    >
      <div
        className="relative flex w-full max-w-[375px] flex-col gap-5 overflow-visible rounded-[20px] border-2 border-white bg-white pb-3 shadow-homeLarge"
        data-testid={testid}
      >
        {chipText && (
          <div className="absolute right-5 top-0 -translate-y-1/2 rounded-full bg-primary px-3 py-1 text-sm font-medium text-white">
            {chipText}
          </div>
        )}
        <div
          className="block w-full overflow-hidden rounded-tl-[20px] rounded-tr-[20px] bg-cover bg-center bg-no-repeat pb-[28%]"
          style={{ backgroundImage: `url(${agencyStyle.images.card})` }}
        />

        <div className="box-border flex w-full flex-col items-center gap-5 px-5 py-0">
          <h3 className="text-over w-full truncate text-2xl font-medium text-primary">
            {convertINameToString(agency.agent)}
            {agency.name ? `, ${agency.name}` : ''}
          </h3>
          <div className="flex w-full flex-col items-start gap-4">
            {!!onMessagingClick ? (
              <button
                onClick={() => onMessagingClick()}
                aria-label="Open Live Chat"
                tabIndex={0}
                className="flex items-center gap-6 underline hover:no-underline"
              >
                <Chat className="w-8 fill-primary" />
                <span>Live Chat</span>
              </button>
            ) : (
              <Link
                to={messagesLink}
                role="Button"
                aria-label={`Send message to ${convertINameToString(agency.agent)}`}
                tabIndex={0}
                className="flex items-center gap-6 underline hover:no-underline"
                data-testid="message-link"
              >
                <Chat className="w-8 fill-primary" />
                <span>Message</span>
              </Link>
            )}

            {!!agency.contact.telephone && (
              <Link
                to={`tel:${agency.contact.telephone}`}
                role="button"
                aria-label={`Call ${agency.contact.telephone}`}
                tabIndex={0}
                className="flex items-center gap-6 underline hover:no-underline"
              >
                <Phone className="w-8 fill-primary" />
                <span>{agency.contact?.telephone}</span>
              </Link>
            )}
            {!!agency.contact.email && (
              <Link
                to={`mailto:${agency.contact.email}`}
                role="button"
                aria-label={`Send email to ${agency.contact.email}`}
                tabIndex={0}
                className="flex items-center gap-6 underline hover:no-underline"
              >
                <Envelope className="w-8 fill-primary" />
                <span>{agency.contact?.email}</span>
              </Link>
            )}
            {!!agency.contact.whatsapp && (
              <Link
                to={agency.contact.whatsapp.link}
                aria-label={`Send WhatsApp message to ${agency.contact.whatsapp.phone}`}
                tabIndex={0}
                target="_blank"
                className="flex items-center gap-6 underline hover:no-underline"
              >
                <WhatsApp className="w-8 fill-primary" />
                <span>{agency.contact.whatsapp.phone}</span>
              </Link>
            )}
          </div>
          {!!hideCoadjutePill ? <div className="h-1"></div> : <CoadjutePill />}
        </div>
      </div>
    </ConditionalWrapper>
  );
};

export const HomeRunnerContactCard = () => (
  <ContactCard
    chipText="Your Home Runner"
    agency={{
      code: Agencies.HOME,
      name: '',
      agent: {
        firstName: 'Home',
        lastName: 'Runner',
      },
      contact: {
        email: 'runners@home.cc',
        telephone: '03300 433 828',
        whatsapp: {
          phone: '+44 7535 742914',
          link: 'https://wa.me/447535742914',
        },
      },
    }}
    onMessagingClick={() => window.LiveChatWidget.call('maximize')}
    testid="home-runner-contact-card"
    hideCoadjutePill
  />
);
