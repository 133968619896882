import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiRoutes } from '@constants/index';
import axios from '@utils/customAxios';

export const fetchInvitation = createAsyncThunk(
  'onboarding/fetchInvitation',
  async ({ id }: { id: string }, thunkAPI) =>
    axios
      .get(ApiRoutes.HOME_INVITATION, {
        params: { id },
        headers: {
          'Home-Api-version': '2023-07-01',
        },
      })
      .then(response => response.data)
      .catch(error => thunkAPI.rejectWithValue(JSON.stringify(error.response)))
);
