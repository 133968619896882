export enum MoveStepIds {
  FindAProperty = 'STEP_FIND_PROPERTY',
  Finances = 'STEP_FINANCES',
  Conveyancing = 'STEP_CONVEYANCING',
  Contracts = 'STEP_CONTRACTS',
  Exchange = 'STEP_EXCHANGE',
  Insurance = 'STEP_INSURANCE',
  Removals = 'STEP_REMOVALS',
  Energy = 'STEP_ENERGY',
  Broadband = 'STEP_BROADBAND',
  GetMoveReady = 'STEP_MOVE_READY',
  Complete = 'STEP_COMPLETE',
  SaleAgreed = 'SALE_AGREED',
}

export type Task = {
  id: string;
  name: string;
  uiName: string;
  status: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED';
  customerOwned: boolean;
  tracking?: 'AUTO' | 'MANUAL';
};

export type Step = {
  id: MoveStepIds;
  progress: number;
  tracking?: 'AUTO' | 'MANUAL';
  tasks: Task[];
};

export type MoveStagesState = {
  value: {
    totalProgress: number;
    steps: Step[];
  };
  isFetching: boolean;
  fetchComplete: boolean;
  errorOccurred: boolean;
};
