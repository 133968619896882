// Hooks
import { useMediaQuery } from '@hooks/useMediaQuery';

// Redux
import { EnhancedMessageRoom } from '@features/messageRooms/messageRooms.types';

// Local
import { Avatar } from '../Common/Avatar';

type ContactProps = {
  active?: boolean;
  room: EnhancedMessageRoom;
  onClick: () => void;
};

const Profession = ({ agents }: { agents: IAgentParticipant[] }) => {
  const createReadableParticipantType = (profession: string) => {
    switch (profession) {
      case 'CONVEYANCER':
        return 'Conveyancer';
      case 'BROKER':
        return 'Broker';
      case 'AGENT':
        return 'Estate Agent';
      default:
        return profession.toLocaleLowerCase();
    }
  };

  return (
    <p className="text-base capitalize">
      {agents
        .map((agent, index, array) => {
          const isLast = index === array.length - 1;
          const separator = isLast && array.length > 1 ? ' & ' : index > 0 ? ', ' : '';
          return `${separator}${createReadableParticipantType(agent.type)}`;
        })
        .join('')}
    </p>
  );
};

export const Contact = ({
  active = false,
  room,

  onClick,
}: ContactProps) => {
  const { IS_MOBILE } = useMediaQuery();

  return (
    <div
      onClick={() => onClick()}
      className="relative flex cursor-pointer gap-5 overflow-hidden border-b border-[rgba(0,0,0,0.2)] bg-white p-6 md:bg-transparent"
      data-testid="contact"
      style={{
        backgroundColor: active || IS_MOBILE ? 'white' : 'transparent',
        boxShadow: active || IS_MOBILE ? `1px 0 0 rgba(255,255,255,1)` : 'none', // When active cover the border with a white box shadow
      }}
    >
      <Avatar
        agentParticipants={room.agentParticipants}
        personParticipants={room.personParticipants}
      />

      <div>
        <h2 className="text-2xl font-medium">{room.formattedRoomName}</h2>

        <Profession agents={room.agentParticipants} />
        {!!room.formattedLastMessage && (
          <p className="w-full pt-1 text-xs lg:w-auto lg:text-sm">
            <span className="text-gray-600">{room.formattedLastMessage}</span>
          </p>
        )}
      </div>
    </div>
  );
};
