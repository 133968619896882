/**
 * These sizes are used to determine the size of the chat window.
 */

export const messagesFixedHeights = {
  HEADER: 80,
  MESSAGES_TITLE_AREA: 208,
  SITE_HEADER: 80 + 208, // HEADER_HEIGHT + MESSAGES_TITLE_AREA
  FORM: 128,
  CHAT_HEADER_MOBILE: 144,
  CHAT_HEADER_DESKTOP: 96,
};
