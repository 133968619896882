// Global
import { useSelector } from 'react-redux';

// Components
import { BreakoutBox } from '@components/WizardContentBlocks';
import { TaskList } from '@components/TaskList';
import { ContactCard, HomeRunnerContactCard } from '@components/ContactCard';
import { Button } from '@components/Button';
import { CircularProgress } from '@components/CircularProgress';
import { Heading } from '@components/Heading';

//Images
import { ReactComponent as HomeItemsSvg } from '@images/home-items.svg';
import { Family } from '@images/icons';

// Contexts
import { useModals } from '@contexts/modalContext';

// Redux
import { selectMoveStages } from '@features/moveStages/moveStages';
import { MoveStepIds } from '@features/moveStages/moveStages.types';
import { selectMove } from '@features/move/move';

// Utils
import { isAgentParticipant } from '@utils/participantTypeChecks';

export const MovingDay = () => {
  const {
    value: { steps, totalProgress },
  } = useSelector(selectMoveStages);
  const { value: move } = useSelector(selectMove);
  const modals = useModals();

  const step = steps.find(step => step.id === MoveStepIds.Complete);

  const agentParticipants = move.participants.filter(p =>
    isAgentParticipant(p)
  ) as IAgentParticipant[];

  return (
    <div className="fixed z-50 h-screen w-screen overflow-y-scroll bg-brand">
      <div className="mx-auto box-border flex w-full max-w-5xl flex-col items-center justify-center gap-10 px-5 py-20 text-white">
        <div className="w-full text-right">
          <button
            className="underline hover:no-underline"
            onClick={() => modals.closeModal('movingDay')}
          >
            Dismiss
          </button>
        </div>
        <h3 className="text-center text-4xl font-medium">Moving Day Information</h3>
        {/* <Typography variant="h5" textAlign="center">
          28th Feb 2023
        </Typography> */}
        <div className="flex flex-col items-center gap-3">
          <CircularProgress percent={totalProgress} />
          <h3 className="text-center text-3xl font-medium">Completion tracker</h3>
          <p className="text-center">Congratulations, you have completed the sale of Flat 4b!</p>
        </div>
        <div className="mx-auto max-w-md">
          <BreakoutBox transparentmobile>
            {!step ? (
              <p className="p-5">Something went wrong fetching your move data</p>
            ) : (
              <TaskList moveId={move.id} tasks={step?.tasks} />
            )}
          </BreakoutBox>
        </div>

        <Heading icon={<Family className="h-20 w-20 fill-white" />} customStyles="!text-3xl">
          Your Move Team
        </Heading>

        <div className="mx-auto box-border grid w-full max-w-5xl grid-cols-1 gap-12 px-5 md:grid-cols-2">
          {agentParticipants.length > 0 &&
            agentParticipants.map((participant, i) => (
              <div className="flex w-full justify-center" key={i}>
                <ContactCard chipText={`Your ${participant.type.toLowerCase()}`} {...participant} />
              </div>
            ))}
          <div className="flex w-full justify-center">
            <HomeRunnerContactCard />
          </div>
        </div>

        <div className="mx-auto flex w-full max-w-lg flex-col items-center justify-center gap-5">
          <h2 className="text-center text-3xl font-medium">What now?</h2>

          <p className="text-center">
            This is the moment you've been waiting for. Your new home is finally yours. It's take to
            step inside and start living.
          </p>
          <Button onClick={() => modals.closeModal('movingDay')}>
            Start living in your new home
          </Button>
          <HomeItemsSvg className="h-auto w-full max-w-96" />
        </div>
      </div>
    </div>
  );
};
