import { ApiRoutes } from '@constants/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@utils/customAxios';

export const fetchMessages = createAsyncThunk(
  'messages/fetchingMessages',
  async (args: { moveId: string; roomId: string; newMessageExpected?: boolean }) =>
    axios
      .get(ApiRoutes.MESSAGES(args.moveId, args.roomId), {
        withCredentials: true,
      })
      .then(response => {
        return {
          data: response.data,
          roomId: args.roomId,
          newMessageExpected: args.newMessageExpected,
        };
      })
);

export const postMessage = createAsyncThunk(
  'messages/postingMessage',
  async (args: { moveId: string; roomId: string; data: any; contentType: string }, thunkAPI) =>
    axios
      .post(ApiRoutes.CREATE_MESSAGE(args.moveId, args.roomId), args.data, {
        withCredentials: true,
        headers: {
          'Content-Type': args.contentType,
        },
      })
      .then(response => {
        return { data: response.data, roomId: args.roomId };
      })
      .catch(error => thunkAPI.rejectWithValue(JSON.stringify(error.response)))
);
