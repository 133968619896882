// Global
import { Link } from 'react-router-dom';

// Components
import { Product, ProductProps } from '@components/Store/product';

type ProductBlockProps = {
  title: string;
  product: ProductProps;
  optionalLink?: {
    text: string;
    href: string;
  };
  smallTitle?: boolean;
};

export const ProductBlock = ({ title, product, optionalLink, smallTitle }: ProductBlockProps) => {
  return (
    <div className="flex w-full max-w-md flex-col items-center gap-6 text-center text-white md:items-start">
      <h3 className={`${smallTitle ? 'text-2xl' : 'text-4xl'} font-medium`}>{title}</h3>
      <Product {...product} single />
      {optionalLink && (
        <p>
          <Link
            to={optionalLink.href}
            target="_blank"
            className="text-white underline hover:no-underline"
          >
            {optionalLink.text}
          </Link>
        </p>
      )}
    </div>
  );
};
