type CircularProgressProps = {
  percent: number;
  wrapperStyles?: string;
  trackStyles?: string;
  progressStyles?: string;
  labelStyles?: string;
};

export const CircularProgress = ({
  percent,
  wrapperStyles,
  trackStyles,
  labelStyles,
  progressStyles,
}: CircularProgressProps) => (
  <div
    className="inline-flex items-center justify-center overflow-hidden"
    role="progressbar"
    aria-valuenow={percent}
    aria-valuemin={0}
    aria-valuemax={100}
    aria-labelledby="circuler-progress-label"
  >
    <div className={`relative h-12 w-12 ${wrapperStyles}`} aria-hidden>
      <svg
        className="h-full w-full"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="18"
          cy="18"
          r="16"
          fill="none"
          opacity={0.25}
          className={`stroke-current text-white  ${trackStyles}`}
          strokeWidth="3"
        ></circle>

        <g className="origin-center -rotate-90 transform">
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className={`stroke-current text-white ${progressStyles}`}
            strokeWidth="3"
            strokeDasharray="100"
            strokeDashoffset={100 - percent}
          ></circle>
        </g>
      </svg>
    </div>
    <span
      id="circuler-progress-label"
      className={`absolute text-xs text-white ${labelStyles}`}
      aria-label={`Progress: ${percent}%`}
    >
      {percent}%
    </span>
  </div>
);
