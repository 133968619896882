// Global
import { ReactNode } from 'react';

// Components
import { Chip } from '@components/Chip';
import { CircularProgress } from '@components/CircularProgress';

// Images
import { Alert } from '@images/icons';

type ContentBlockProps = {
  manualStepUserType?: string;
  percentageComplete?: number;
  leftColumn: ReactNode;
  rightColumn: ReactNode;
};

export const ContentWrapper = ({
  manualStepUserType,
  percentageComplete,
  leftColumn,
  rightColumn,
}: ContentBlockProps) => {
  return (
    <>
      {typeof percentageComplete === 'number' && (
        <div className="mb-6 flex flex-col gap-6">
          <div className="flex flex-col items-center gap-3">
            {percentageComplete < 100 ? (
              <>
                {percentageComplete > 0 && !manualStepUserType && (
                  <CircularProgress
                    percent={percentageComplete}
                    wrapperStyles="w-16 h-16"
                    trackStyles="hidden"
                    labelStyles="!text-base"
                  />
                )}
                <h3 className="text-center text-3xl font-medium text-white">Progress Tracker</h3>
                <p className="pb-6 text-center text-white">
                  {!!manualStepUserType
                    ? `Here's a list of the steps that your ${manualStepUserType} will be working through.`
                    : 'Track everything, right here with our revolutionary real-time information sharing.'}
                </p>
                {percentageComplete === 0 && !manualStepUserType && (
                  <Chip icon={<Alert />}>Not ready yet</Chip>
                )}
              </>
            ) : (
              <>
                <div className="flex flex-col items-center gap-4 pb-6">
                  <h3 className="text-center text-4xl font-medium text-white">
                    Great news! Step completed.
                  </h3>
                  <p className="text-center text-white">
                    Track everything, right here with our revolutionary real-time information
                    sharing.
                  </p>
                </div>
              </>
            )}
          </div>
          <hr
            className=" block h-0.5 w-full border-none bg-[rgba(0,0,0,0.1)] md:hidden"
            style={{
              boxShadow: `30px 0 0 rgba(0,0,0,0.1), -30px 0 0 rgba(0,0,0,0.1)`,
            }}
            role="presentation"
          />
        </div>
      )}

      <div className="grid grid-cols-1 gap-x-16 gap-y-8 md:grid-cols-2 md:gap-y-0">
        <div className="flex flex-1 flex-col items-center justify-center gap-8 md:items-start md:justify-start">
          {leftColumn}
        </div>

        <hr
          className=" block h-0.5 w-full border-none bg-[rgba(0,0,0,0.1)] md:hidden"
          style={{
            boxShadow: `30px 0 0 rgba(0,0,0,0.1), -30px 0 0 rgba(0,0,0,0.1)`,
          }}
          role="presentation"
        />

        <div className="flex flex-1 flex-col items-center justify-center gap-16 md:items-start md:justify-start md:gap-14">
          {rightColumn}
        </div>
      </div>
    </>
  );
};
