// Utils
import { getFileImage } from '@utils/getFileImage';
import { humanFileSize } from '@utils/humanFileSizes';

// Images
import downloadImg from '@images/icons/download.svg';

export const Attachment = ({
  attachment,
  moveId,
  roomId,
}: {
  attachment: MessageAttachment;
  moveId: string;
  roomId: string;
}) => {
  const fileImage = getFileImage(attachment.type);

  const size =
    attachment.size >= 0 ? <span className="text-xs">{humanFileSize(attachment.size)}</span> : null;

  return (
    <div className="flex items-center justify-between rounded-xl bg-white">
      <a
        href={`/api/home/moves/${moveId}/message-room/${roomId}/attachment/${attachment.id}`}
        target="_blank"
        className="flex h-full items-start justify-between gap-4 p-2"
        rel="noreferrer"
      >
        <img alt="File type icon" src={fileImage} className="min-w-5" aria-hidden />
        <div className="flex flex-col gap-1">
          <span className="max-w-48 break-words font-bold">{attachment.name}</span>
          {size}
        </div>
      </a>
      <a
        href={`/api/home/moves/${moveId}/message-room/${roomId}/attachment/${attachment.id}`}
        download={attachment.name}
        className="has-tooltip relative -mt-2 overflow-visible rounded-full px-2 py-2 hover:bg-gray-100"
      >
        <img src={downloadImg} alt="Download file" className="md:min-w-7" />
        <span className="tooltip invisible absolute left-1/2 top-[88px] -mt-8 -translate-x-1/2 rounded bg-primary p-2 text-sm text-white shadow-lg transition-all duration-300">
          Download
        </span>
      </a>
    </div>
  );
};
