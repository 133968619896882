// Global
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

// Components
import { MoveTiles } from '@components/MoveTiles';

// Redux
import { Move } from '@features/move/move.types';

// Images
import { ArrowBack, ArrowForward, Tick } from '@images/icons';

export type WizardTemplateProps = {
  nextHref?: string;
  prevHref?: string;
  totalStepCount: number;
  index: number;
  title: string;
  icon: ReactNode;
  intro: string | ReactNode;
  children: ReactNode;
  isComplete?: boolean;
  additionalContent?: ReactNode;
  styling?: {
    backgroundcolour?: string;
  };
  move: Move;
};

export const WizardTemplate = ({
  nextHref = undefined,
  prevHref = undefined,
  totalStepCount,
  index,
  title,
  icon,
  intro,
  children,
  additionalContent,
  styling,
  isComplete,
  move,
}: WizardTemplateProps) => {
  const iconWithStyles = React.cloneElement(icon as React.ReactElement, {
    className: 'fill-primary',
  });

  return (
    <>
      <div className="my-5">
        <div className="mx-auto box-border w-full max-w-7xl px-5 pb-3">
          <div className="h-1 w-full bg-white">
            <div
              className="h-1 bg-primary"
              style={{ width: `${((index + 1) / totalStepCount) * 100}%` }}
            />
          </div>
          <nav className="mx-auto my-0 flex w-full max-w-2xl items-center justify-between">
            <Link
              className={`${
                !!prevHref
                  ? ' pointer-events-auto cursor-pointer'
                  : 'pointer-events-none cursor-default opacity-50'
              } select-none p-5`}
              to={prevHref || ''}
              data-testid="navigate-back"
              aria-label="Previous Step"
            >
              <ArrowBack className="h-4 fill-primary" />
            </Link>

            <header className="my-4 flex w-full flex-col items-center justify-center gap-3 p-0">
              <div className="flex h-[75px] items-center justify-center">
                {!!isComplete ? <Tick className="h-[57px] fill-primary" /> : iconWithStyles}
              </div>
              <h2 className="text-center text-3xl font-medium md:text-4xl">{title}</h2>
            </header>

            <Link
              className={`${
                !!nextHref
                  ? ' pointer-events-auto cursor-pointer'
                  : 'pointer-events-none cursor-default opacity-50'
              } select-none p-5`}
              to={nextHref || ''}
              data-testid="navigate-forward"
              aria-label="Next Step"
            >
              <ArrowForward className="h-4 fill-primary" />
            </Link>
          </nav>
          <div className="mx-auto my-0 flex w-full max-w-2xl flex-col items-center justify-center gap-5 pb-3 text-center">
            {intro}
          </div>
        </div>

        <main
          className="box-border w-full bg-brand px-5 pb-20 pt-8 md:pt-16"
          style={{ backgroundColor: styling?.backgroundcolour }}
        >
          <div className="mx-auto box-border w-full max-w-6xl">{children}</div>
        </main>

        {additionalContent}

        <div className="mx-auto mb-0 mt-20 box-border flex w-full max-w-6xl items-center justify-center px-4 lg:p-0">
          {prevHref && (
            <Link className="flex-1 py-5 text-center uppercase no-underline" to={prevHref}>
              Previous
            </Link>
          )}
          {prevHref && nextHref && <span className="h-12 w-0.5 bg-primary" aria-hidden />}
          {nextHref && (
            <Link className="flex-1 py-5 text-center uppercase no-underline" to={nextHref}>
              Next
            </Link>
          )}
        </div>

        <div className="mb-10 mt-20 w-full text-center">
          <Link className="underline hover:no-underline" to="/">
            Back to Home hub
          </Link>
        </div>
      </div>
      <MoveTiles move={move} />
    </>
  );
};
