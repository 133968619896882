// Global
import React from 'react';

// Utils
import { getAgencyStyling } from '@utils/agencyStyling';
import { useMediaQuery } from '@hooks/useMediaQuery';

interface AvatarProps {
  agentParticipants: IAgentParticipant[];
  personParticipants: IPersonParticipant[];
  smallerOnTablet?: boolean;
}

export const Avatar: React.FC<AvatarProps> = ({
  agentParticipants,
  personParticipants,
  smallerOnTablet,
}) => {
  const { IS_TABLET } = useMediaQuery();

  const totalIconsToShow = agentParticipants.length + (personParticipants.length > 0 ? 1 : 0);

  const avatarSize = smallerOnTablet && IS_TABLET ? '64px' : '77px';

  const determineSizeAndPosition = (totalIcons: number, order: number) => {
    const sizes = {
      '1': 'w-full h-full',
      '2': smallerOnTablet && IS_TABLET ? 'w-9 h-9' : 'w-11 h-11',
      '3': smallerOnTablet && IS_TABLET ? 'w-8 h-8' : 'w-10 h-10',
      '4': smallerOnTablet && IS_TABLET ? 'w-8 h-8' : 'w-10 h-10',
    };

    const positions = {
      '2': ['top-0.5 left-0.5', 'bottom-0.5 right-0.5'],
      '3': ['top-4 left-0', 'top-0.5 right-0.5', 'bottom-0.5 right-0.5'],
      '4': ['top-0.5 left-0.5', 'top-0.5 right-0.5', 'bottom-0.5 left-0.5', 'bottom-0.5 right-0.5'],
    };

    if (totalIcons === 1 || totalIcons > 4) return sizes[1];

    const size = sizes[totalIcons.toString() as keyof typeof sizes];
    const position = positions[totalIcons.toString() as keyof typeof positions][order];

    return `${size} ${position}`;
  };

  return (
    <div
      className={`relative rounded-full bg-gray-200`}
      style={{ flex: `0 0 ${avatarSize}`, height: avatarSize }}
      aria-hidden
    >
      {agentParticipants.map((agent, index) => (
        <img
          key={`agent-icon-${index}`}
          src={getAgencyStyling(agent.agency.code).images.circleSmall}
          className={`absolute ${determineSizeAndPosition(totalIconsToShow, index)}`}
          alt={`${agent.agency.name} logo`}
        />
      ))}
      {personParticipants.length > 0 && (
        <span
          className={`absolute flex items-center justify-center rounded-full bg-gray-900 text-xl font-medium text-white ${determineSizeAndPosition(
            totalIconsToShow,
            totalIconsToShow - 1
          )} uppercase`}
        >
          {agentParticipants.length > 3
            ? `+${personParticipants.length + agentParticipants.length}`
            : personParticipants.length === 1
              ? personParticipants[0].person.name.firstName.charAt(0) +
                personParticipants[0].person.name.lastName.charAt(0)
              : `+${personParticipants.length}`}
        </span>
      )}
    </div>
  );
};
