import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { resetAll } from '@features/common';

import { MoveStagesState } from './moveStages.types';
import { fetchMoveStages } from './moveStages.thunks';

export const initialMoveStagesState: MoveStagesState = {
  value: {
    totalProgress: 0,
    steps: [],
  },
  isFetching: false,
  fetchComplete: false,
  errorOccurred: false,
};

export const moveStagesSlice = createSlice({
  name: 'moveStage',
  initialState: initialMoveStagesState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchMoveStages.pending.type, (state: MoveStagesState) => {
      state.isFetching = true;
      state.errorOccurred = false;
    });
    builder.addCase(fetchMoveStages.fulfilled.type, (state: MoveStagesState, action: any) => {
      state.isFetching = false;
      state.fetchComplete = true;
      state.errorOccurred = false;
      state.value = action.payload;
    });
    builder.addCase(fetchMoveStages.rejected.type, (state: MoveStagesState) => {
      state.isFetching = false;
      state.errorOccurred = true;
    });
    builder.addCase(resetAll, () => initialMoveStagesState);
  },
});

export const selectMoveStages = (state: RootState) => state.moveStages;

export const moveStagesReducer = moveStagesSlice.reducer;
