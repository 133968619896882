// Global
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

// Images
import { Alert } from '@images/icons';

type ErrorMessageProps = {
  children?: ReactNode;
  includeHomeRunnerContact?: boolean;
};

const HomeRunnerContact = () => (
  <>
    Please try again later or speak to a <b>Home Runner</b> by starting a{' '}
    <span onClick={() => window.LiveChatWidget.call('maximize')} className="font-bold">
      live chat
    </span>
    , emailing{' '}
    <Link to="mailto:hello@home.cc" className="font-bold">
      hello@home.cc
    </Link>{' '}
    or phoning{' '}
    <Link to="tel:03300433828" className="font-bold">
      03300 433 828
    </Link>
  </>
);

export const ErrorMessage = ({ children, includeHomeRunnerContact = false }: ErrorMessageProps) => {
  return (
    <div className="flex h-auto w-full items-center gap-5 rounded-xl bg-red-100 p-5">
      <Alert aria-hidden="true" width="40px" height="40px" className="h-10 w-10 min-w-6" />
      <p className="text-base">
        {children ? (
          <>
            {children}
            {includeHomeRunnerContact && (
              <>
                <br />
                <br />
                <HomeRunnerContact />
              </>
            )}
          </>
        ) : (
          <>
            Oops, an error has occurred.
            <br />
            <HomeRunnerContact />
          </>
        )}
      </p>
    </div>
  );
};
