import { selectMoveStages } from '@features/moveStages/moveStages';
import { MoveStepIds } from '@features/moveStages/moveStages.types';
import { useSelector } from 'react-redux';

export const useIsTaskCompleted = (stepId: MoveStepIds, taskName: string) => {
  const {
    value: { steps },
  } = useSelector(selectMoveStages);
  const step = steps.find(step => step.id === stepId);

  if (!step) return false;

  const task = step.tasks.find(task => task.name === taskName);

  if (!task) return false;

  return task.status === 'COMPLETED';
};
