import React from 'react';
import { Header } from './Header';
import { EnhancedMessageRoom } from '@features/messageRooms/messageRooms.types';
import { Messages } from './Messages';
import { Spinner } from '@components/Spinner';
import { MessageForm } from './Form';
import { Files } from './Files';
import { MessagesState } from '@features/messages/messages';

type RoomProps = {
  room: EnhancedMessageRoom;
  formattedMessages: MessagesByDate[];
  moveId: string;
  messagesState: MessagesState;
  isActive: boolean;
  closeChat: () => void;
  height: number | null;
};

export const Room = ({
  room,
  formattedMessages,
  moveId,
  isActive,
  messagesState,
  closeChat,
  height,
}: RoomProps) => {
  const [areChatFilesOpen, setAreChatFilesOpen] = React.useState(false);

  const status = messagesState.messageLists.find(list => list.id === room.id)?.status;

  const hasFetchedInitialMessages = status && status.initialFetchComplete;

  if (!isActive) return null;

  if (!hasFetchedInitialMessages || !height) {
    return (
      <main className="col-span-2 flex border-[rgba(0,0,0,0.2)] md:border-l">
        <div
          className={`flex h-screen w-full flex-col justify-center bg-white md:h-auto md:w-full md:items-start md:justify-between md:overflow-hidden`}
        >
          <div className="flex h-full w-full flex-col items-center justify-center">
            <Spinner colour="black" label="Loading messages" />
          </div>
        </div>
      </main>
    );
  }

  if (areChatFilesOpen) {
    return (
      <main className="col-span-2 flex border-[rgba(0,0,0,0.2)] md:border-l">
        <div className="flex w-full flex-col justify-start bg-secondary md:w-full md:items-start md:overflow-hidden md:bg-white">
          <Header
            backButtonAction={() => setAreChatFilesOpen(false)}
            agents={room.agentParticipants}
            persons={room.personParticipants}
            filesButtonAction={() => setAreChatFilesOpen(true)}
            areChatFilesOpen={areChatFilesOpen}
          />
          <Files room={room} messages={formattedMessages} height={height} />
        </div>
      </main>
    );
  }

  return (
    <main className="col-span-2 flex border-[rgba(0,0,0,0.2)] md:border-l">
      <div className="flex w-full flex-col bg-secondary md:w-full md:items-start md:justify-between md:overflow-hidden md:bg-white">
        <Header
          backButtonAction={closeChat}
          agents={room.agentParticipants}
          persons={room.personParticipants}
          filesButtonAction={() => setAreChatFilesOpen(true)}
          areChatFilesOpen={areChatFilesOpen}
        />
        <Messages
          messages={formattedMessages}
          room={room}
          moveId={moveId}
          height={height}
          fetchError={status.fetchError}
        />
        <MessageForm room={room} isSending={status.isPosting} postError={status.postError} />
      </div>
    </main>
  );
};
