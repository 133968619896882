// Utils
import { getAgencyStyling } from '@utils/agencyStyling';
import { convertINameToString } from '@utils/convertINameToString';
import { isAgentParticipant } from '@utils/participantTypeChecks';

// Local
import { Attachment } from './Attachment';

const linkifyText = (text: string) => {
  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]|\bwww\.[a-z0-9\-.]+[a-z]{2,})\b/gi;

  return text.replace(urlRegex, url => {
    let hyperlink = url;
    if (!hyperlink.match('^https?://')) {
      hyperlink = 'https://' + hyperlink;
    }
    return `<a href="${hyperlink}" target="_blank">${url}</a>`;
  });
};

export const Message = ({
  message,
  moveId,
  roomId,
}: {
  message: Message;
  moveId: string;
  roomId: string;
}) => {
  const participant = message.participant;

  const isAgent = !!participant ? isAgentParticipant(participant) : false;

  const agentCastParticipant = participant as IAgentParticipant;
  const personCastParticipant = participant as IPersonParticipant;

  const name = !participant
    ? ''
    : isAgentParticipant(participant)
      ? convertINameToString(agentCastParticipant?.agency?.agent)
      : convertINameToString(personCastParticipant.person.name);

  return (
    <>
      {message.who !== 'YOU' ? (
        <div className="flex w-full items-end gap-3" aria-live="polite" aria-atomic="true">
          {isAgent && (
            <div className="flex h-8 w-8 flex-shrink-0 rounded-full" aria-hidden>
              <img
                src={getAgencyStyling(agentCastParticipant?.agency.code).images.circleSmall}
                style={{ width: '100%' }}
                alt={`${agentCastParticipant.agency.name} logo`}
                role="presentation"
              />
            </div>
          )}
          {!!personCastParticipant && !isAgent && (
            <span className=" flex h-8 min-w-8 items-center justify-center rounded-full bg-gray-900 text-sm font-medium uppercase  text-white">
              {personCastParticipant.person.name.firstName.charAt(0) +
                personCastParticipant.person.name.lastName.charAt(0)}
            </span>
          )}

          <div className="flex flex-col items-start gap-1">
            {!!message.attachment ? (
              <div
                className="whitespace-pre-wrap rounded-t-2xl rounded-br-2xl rounded-tr-2xl border border-gray-200 bg-white px-4 py-2 text-base"
                aria-label={
                  !!message.attachment
                    ? `Recieved file from ${name}`
                    : `${name}: ${message.text} at ${message.time}`
                }
              >
                <Attachment attachment={message.attachment} moveId={moveId} roomId={roomId} />
              </div>
            ) : (
              <div
                className="whitespace-pre-wrap rounded-t-2xl rounded-br-2xl rounded-tr-2xl border border-gray-200 bg-white px-4 py-2 text-base"
                dangerouslySetInnerHTML={{
                  __html: linkifyText(message.text),
                }}
              />
            )}
            <span className="text-xs" aria-hidden>
              {message.time}
            </span>
          </div>
        </div>
      ) : (
        <div className="flex w-full flex-col items-end gap-1" aria-live="polite" aria-atomic="true">
          {!!message.attachment ? (
            <div
              className="whitespace-pre-wrap rounded-t-2xl rounded-bl-2xl rounded-tl-2xl bg-gray-200 p-1 text-base"
              aria-label={
                !!message.attachment ? 'Sent file' : `You: ${message.text} at ${message.time}`
              }
            >
              <Attachment attachment={message.attachment} moveId={moveId} roomId={roomId} />
            </div>
          ) : (
            <div
              className="whitespace-pre-wrap rounded-t-2xl rounded-bl-2xl rounded-tl-2xl bg-gray-200 px-4 py-2 text-base"
              dangerouslySetInnerHTML={{
                __html: linkifyText(message.text),
              }}
            />
          )}
          <span className="text-xs" aria-hidden>
            {message.time}
          </span>
        </div>
      )}
    </>
  );
};
