// Global
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { HomeLogo } from '@components/HomeLogo';

// Constants
import { ApiRoutes } from '@constants/index';

// Icons
import { Padlock, User } from '@images/icons';

// Redux
import { resetAll } from '@features/common';

// Utils
import axios from '@utils/customAxios';

// Local
import { Navigation } from '@components/Navigation';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { selectProfile } from '@features/profile/profile.ts';

interface IHeaderProps {
  loggedIn?: boolean;
  hideNavigation?: boolean;
}

export const HEADER_HEIGHT = 80; // Used in messaging to calculate the height of the message stream

export const Header = ({ loggedIn, hideNavigation }: IHeaderProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { IS_TABLET } = useMediaQuery();
  const profile = useSelector(selectProfile);
  const hasCoadjuteId = profile?.value?.coadjuteParticipantId !== undefined;

  const signOut = async () => {
    const result = await axios.post(ApiRoutes.SIGNOUT, {
      withCredentials: true,
    });

    if (result?.status === 200) {
      dispatch(resetAll());
      navigate('/signin');
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const accountRef = useRef<HTMLButtonElement>(null);

  const handleClick = (e: MouseEvent) => {
    if (accountRef.current && accountRef.current.contains(e.target as Node)) {
      setIsOpen(true);
    } else if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <header
      className="z-40 flex w-full items-center justify-between px-6 py-2"
      style={{ height: `${HEADER_HEIGHT}px` }}
    >
      <HomeLogo />
      {loggedIn ? (
        <nav className="flex items-center gap-7">
          {IS_TABLET && !hideNavigation && <Navigation hasCoadjuteId={hasCoadjuteId} />}

          <button
            role="navigation"
            ref={accountRef}
            onClick={() => setIsOpen(true)}
            aria-label="Main menu"
            data-testid="authenticated-button"
            className="flex items-center gap-3 transition-all md:gap-2 md:rounded-lg md:bg-primary md:px-5 md:py-[9px] md:text-sm md:font-medium md:text-white md:hover:bg-gray-400"
          >
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-primary md:hidden">
              <User className="h-8 w-8 fill-white md:w-5" />
            </div>
            <User className="hidden h-5 fill-white md:block" />
            <span className="hidden md:block">My Account</span>
          </button>
        </nav>
      ) : (
        <Link
          to="/signin"
          className="flex items-center gap-2 rounded-full px-4 py-2 text-base font-medium text-primary transition-all hover:bg-[rgba(0,0,0,0.05)]"
        >
          <Padlock className="h-5" />
          Sign in
        </Link>
      )}
      {loggedIn && isOpen && (
        <div className="z-100 animate-fadeIn absolute left-0 top-0 h-screen w-screen bg-white bg-opacity-55">
          <div
            ref={dropdownRef}
            className="absolute right-16 top-14 z-50 mt-2 origin-top-right  divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-2xl focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex={-1}
          >
            <div className="py-1" role="none">
              <button
                onClick={signOut}
                className="block w-full px-4 py-2 text-left text-lg text-gray-700 hover:bg-gray-100"
                role="menuitem"
              >
                Sign out
              </button>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};
