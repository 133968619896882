// Global
import { ReactNode } from 'react';

// Components
import { Homebox } from '@components/Homebox';
import { Button } from '@components/Button';
import { Step } from '@features/moveStages/moveStages.types';
import { TaskList } from '@components/TaskList';
import { BreakoutBox } from '../BreakoutBox';

type ContentBlockProps = {
  type: 'energy' | 'broadband';
  children?: ReactNode;
  step?: Step;
  moveId?: string;
};

const HomeboxContentWrapper = ({ children, type, step, moveId = '' }: ContentBlockProps) => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-7 md:gap-14">
      <Homebox serviceType={type} includeBundles />
      {step && (
        <div className="w-full max-w-xl">
          <BreakoutBox transparentmobile>
            <TaskList title="Track your to-do list" moveId={moveId} tasks={step?.tasks} />
          </BreakoutBox>
        </div>
      )}

      <div className="mx-auto flex max-w-4xl flex-col items-center justify-center gap-9 px-5 pb-6 md:px-0">
        <h2 className="text-center text-4xl font-medium text-white">
          Don't forget, your Home Runner is on hand to help
        </h2>
        <p className="text-center text-white">
          We're on hand to take the hassle out of tasks like setting up your {type}. First we'll
          search the market for you and find your perfect tariff and then, when you're ready, we can
          even take care of the setup. Just a reminder - this is a completely free service.
        </p>
        <Button onClick={() => window.LiveChatWidget.call('maximize')}>Set up {type} for me</Button>
      </div>
      <div className="grid max-w-4xl grid-cols-1 justify-center gap-11 md:grid-cols-2">
        {children}
      </div>
    </div>
  );
};

const Column = ({ children }: { children: ReactNode }) => {
  return <div className="flex justify-center md:justify-start">{children}</div>;
};

HomeboxContentWrapper.Column = Column;

export { HomeboxContentWrapper };
