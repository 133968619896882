// Images
import { ChevronLeft, ChevronRight } from '@images/icons';

export const Arrow = ({ handleClick, isLeft }: { handleClick: () => void; isLeft?: boolean }) => (
  <div
    className={`absolute bottom-0 top-0 flex w-[50px] items-center justify-center md:w-[94px] ${
      isLeft ? 'left-0' : 'right-0'
    }`}
  >
    <button
      className="z-10 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-white md:h-14 md:w-14"
      onClick={handleClick}
      aria-label={isLeft ? 'Previous' : 'Next'}
    >
      {isLeft ? (
        <ChevronLeft className="h-5 w-5 fill-primary md:h-6 md:w-6" />
      ) : (
        <ChevronRight className="h-5 w-5 fill-primary md:h-6 md:w-6" />
      )}
    </button>
  </div>
);
