// Global
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

// Constants
import { ATTACHMENT_FILE_TYPES } from '@constants/coadjute';

// Images
import { ArrowRightCircle, Attachment } from '@images/icons';

// Redux
import { selectMove } from '@features/move/move';
import { AppThunkDispatch } from 'src/App';
import { postMessage } from '@features/messages/messages.thunks';

// Local
import { messagesFixedHeights } from '../Common/sizes';

const formSchema = Yup.object().shape({
  message: Yup.string().min(2).max(2500).required('Message is required'),
});

type StatusProps = {
  type: 'sending' | 'error';
  children: React.ReactNode;
};

const Status = ({ children, type }: StatusProps) => {
  const typeStyles =
    type === 'error' ? 'bg-error text-white py-4 h-24 -top-24' : 'bg-gray-200 h-12 -top-12';

  return (
    <div
      className={`xxl:w-[104%] absolute left-0 z-10 flex w-full flex-col items-center justify-center text-center text-base md:w-[67vw] ${typeStyles}`}
    >
      {children}
    </div>
  );
};

type MessageFormProps = {
  room: MessageRoom;
  isSending: boolean;
  postError?: number | null;
};

export const MessageForm = ({ room, isSending, postError }: MessageFormProps) => {
  const { register, handleSubmit, reset, watch } = useForm({
    resolver: yupResolver(formSchema),
  });
  const { value: move } = useSelector(selectMove);

  const dispatch = useDispatch<AppThunkDispatch>();

  const fileRef = useRef<HTMLInputElement>(null);

  // actual limit is 1024 bytes, but some characters are 2 bytes.
  const maxLength = 1000;

  const submitMessage = async (data: any) => {
    dispatch(
      postMessage({
        moveId: move.id,
        roomId: room.id,
        data: data.message,
        contentType: 'text/plain',
      })
    );
  };

  const submitAttachment = async (event: any) => {
    const file = event.target.files[0];

    if (!file) return;

    const formData = new FormData();

    formData.append('file', file);

    dispatch(
      postMessage({
        moveId: move.id,
        roomId: room.id,
        data: formData,
        contentType: 'multipart/form-data',
      })
    );
  };

  const handleAttachmentClick = () => {
    fileRef.current?.click();
  };

  useEffect(() => {
    if (!isSending && !postError) reset();
  }, [isSending, reset]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="border-grey-200 w-full md:border-t">
      <form
        className="relative bottom-0 left-0 z-[10000] box-border flex w-full items-center justify-start gap-3 border-t border-gray-200 bg-white p-4 py-2 md:max-w-[calc(100%-44px)] md:items-end md:border-t-0 md:py-3"
        style={{
          height: `${messagesFixedHeights.FORM}px`,
          minHeight: `${messagesFixedHeights.FORM}px`,
        }}
        onSubmit={handleSubmit(submitMessage)}
      >
        {isSending && <Status type="sending">Sending...</Status>}
        {postError && (
          <Status type="error">
            <b>
              {postError === 413
                ? 'Message is too large'
                : postError === 422
                  ? 'This message contains unacceptable characters'
                  : 'An error has occurred'}
            </b>
            <span>
              Your message has not been sent.
              <button
                className="m-0 inline-block w-20 border-none bg-transparent p-0 align-baseline text-base text-white underline hover:no-underline"
                type="submit"
              >
                Try again.
              </button>
            </span>
          </Status>
        )}
        <button
          type="button"
          className="w-10 border-none bg-transparent p-0"
          onClick={() => handleAttachmentClick()}
          aria-label="Upload attachment"
        >
          <Attachment className="h-[30px] w-[30px]" />
        </button>
        <div className="relative flex w-full">
          <label htmlFor="message" className="visually-hidden">
            Message
          </label>
          <textarea
            {...register('message')}
            id="message"
            className="border-box peer h-[100px] w-full resize-none scroll-pb-5 rounded-xl border border-gray-300 bg-transparent px-3 py-3 pb-5 text-base focus:border-primary focus:outline-primary"
            placeholder=" "
            disabled={isSending}
          />
          <span className="absolute bottom-1 right-3 text-xs text-gray-700">
            {watch('message')?.length || 0}/{maxLength}
          </span>
        </div>
        <button
          className="min-w-14 border-none bg-transparent p-0 disabled:opacity-50"
          type="submit"
          disabled={isSending}
          aria-label="Send message"
          tabIndex={0}
        >
          <ArrowRightCircle className="h-14 w-14 fill-primary md:h-11 md:w-11" />
        </button>
      </form>
      <input
        type="file"
        ref={fileRef}
        style={{ display: 'none' }}
        accept={ATTACHMENT_FILE_TYPES}
        onChange={submitAttachment}
        aria-label="File Upload"
      />
    </div>
  );
};
