import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiRoutes } from '@constants/index';
import axios from '@utils/customAxios';

export const fetchMove = createAsyncThunk('move/fetchMove', (moveId: string) =>
  axios
    .get(ApiRoutes.MOVE(moveId), {
      withCredentials: true,
    })
    .then(response => response.data)
);
