type TextBlockProps = {
  title?: string;
  text?: string;
  list?: string[];
  optionalLink?: {
    text: string;
    href: string;
  };
  smallTitle?: boolean;
};

export const TextBlock = ({ title, text, smallTitle }: TextBlockProps) => {
  return (
    <div className="flex w-full flex-col gap-5 text-center text-white md:text-left">
      {title && <h3 className={`${smallTitle ? 'text-2xl' : 'text-4xl'} font-medium`}>{title}</h3>}

      {text && <p>{text}</p>}
    </div>
  );
};
