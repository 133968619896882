// Hooks
import { useMediaQuery } from '@hooks/useMediaQuery';

// Images
import { ChevronLeft, Document } from '@images/icons';

// Utils
import { createRoomName } from '@utils/createRoomName';

// Local
import { Avatar } from '../Common/Avatar';
import { messagesFixedHeights } from '../Common/sizes';

type HeaderProps = {
  agents: IAgentParticipant[];
  persons: IPersonParticipant[];
  backButtonAction: () => void;
  filesButtonAction: () => void;
  areChatFilesOpen: boolean;
};

export const Header = ({
  areChatFilesOpen,
  backButtonAction,
  filesButtonAction,
  agents,
  persons,
}: HeaderProps) => {
  const { IS_DESKTOP, IS_TABLET } = useMediaQuery();

  return (
    <header
      className={`border-bottom-gray-200 z-50 flex w-full flex-col justify-center gap-3 border-b px-5`}
      style={{
        height: `${IS_TABLET ? messagesFixedHeights.CHAT_HEADER_DESKTOP : messagesFixedHeights.CHAT_HEADER_MOBILE}px`,
        minHeight: `${IS_TABLET ? '' : messagesFixedHeights.CHAT_HEADER_MOBILE}px`, // Force height on android & iOS
      }}
      data-testid="messages-header"
    >
      <div className="flex w-full items-center justify-between md:h-full">
        <div className="flex flex-1 justify-start">
          <button
            className={`flex items-center gap-3 rounded-full px-3 py-3 text-base transition-all hover:bg-gray-100 lg:text-lg ${
              areChatFilesOpen ? 'md:flex' : 'md:hidden'
            }`}
            onClick={backButtonAction}
            aria-label="Back Button"
          >
            <ChevronLeft className="h-6 w-6 fill-primary" />
            {areChatFilesOpen ? (IS_DESKTOP ? 'Back to messages' : 'Messages') : 'Back'}
          </button>
        </div>
        <div className="flex flex-1 flex-row items-center justify-center gap-4">
          <Avatar agentParticipants={agents} personParticipants={persons} smallerOnTablet />
          {IS_TABLET && (
            <h3 className="text-center text-xl font-medium leading-none">
              {createRoomName(agents, persons)}
            </h3>
          )}
        </div>
        <div className="flex flex-1 justify-end">
          {!areChatFilesOpen && (
            <button
              className="flex w-28 items-center justify-center gap-3 rounded-full py-3 transition-all hover:bg-gray-100"
              onClick={filesButtonAction}
              aria-label="Files Button"
            >
              <Document />
              Files
            </button>
          )}
        </div>
      </div>
      {!IS_TABLET && (
        <h3 className="text-center text-xl font-medium leading-none">
          {createRoomName(agents, persons)}
        </h3>
      )}
    </header>
  );
};
