// External libraries
import { Suspense, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

// Redux
import { selectEstateAgent } from '@features/move/move';

// Templates
import { Authenticated } from '@templates/Authenticated';
import { Common } from '@templates/Common';

// Pages
import { NotFound } from './pages/notFound';

// Contexts
import { ModalProvider } from '@contexts/modalContext';

// Components
import { PageLoading } from '@components/PageLoading';

// Utilities
import { handleResizeObserverError } from './utils/handleResizeObserverError';

// Styles
import 'react-toastify/dist/ReactToastify.css';

// Local
import { RootState } from './store';
import { ScrollToTop } from './scrollToTop';
import { LiveChat } from './livechat';
import { Unauthenticated } from '@templates/Unauthenticated';
import { routeConfig, routeRedirects } from './routeConfig';

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

export const App = () => {
  // Catching "ResizeObserver loop limit exceeded" error, which is caused by Material UI or Homebox
  useEffect(() => {
    handleResizeObserverError();
  }, []);

  const agent = useSelector(selectEstateAgent) as IAgentParticipant;

  const dataTheme = agent?.agency.code === 'FOXTONS' ? 'foxtons' : 'default';

  return (
    <main data-theme={dataTheme} className="bg-secondary">
      <LiveChat />
      <ToastContainer />
      <BrowserRouter>
        <ScrollToTop />
        <ModalProvider>
          <Suspense fallback={<PageLoading />}>
            <Routes>
              {routeConfig.map((route, index) => {
                const RouteComponent = route.component;

                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      !!route.private && !!route.public ? (
                        <Common title={route.title} component={<RouteComponent />} />
                      ) : !!route.private ? (
                        <Authenticated title={route.title} component={<RouteComponent />} />
                      ) : (
                        <Unauthenticated title={route.title} component={<RouteComponent />} />
                      )
                    }
                  />
                );
              })}
              {routeRedirects.map((route, index) => (
                <Route key={index} path={route.from} element={<Navigate replace to={route.to} />} />
              ))}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </ModalProvider>
      </BrowserRouter>
    </main>
  );
};
