import { LinkButton } from '@components/Button';
import { useHomebox } from '../../hooks/useHomebox';
import BundlesLogos from '@images/bundles-logos.png';

type HomeboxProps = {
  serviceType?: string;
  includeBundles?: boolean;
};

export const Homebox = ({ serviceType, includeBundles }: HomeboxProps) => {
  useHomebox({ serviceType });

  const minHeight = includeBundles ? 'min-h-[868px]' : 'min-h-[580px]'; // Not exact as content is dynamic, roughly calculated to minimise flashing of below content

  return (
    <div className={`w-full ${minHeight}`}>
      <div className="mx-auto w-full max-w-[1000px] overflow-hidden rounded-[15px] bg-white shadow-homeLarge">
        <div id="homeboxComparison" data-testid="homebox" className="min-h-[580px]" />
        {includeBundles && (
          <div className="flex flex-col gap-5 border-t border-[#D7D7D7] bg-[#F6F6F6] p-12">
            <h3 className="text-2xl font-medium">
              You could save time and money with home bundles
            </h3>
            <p className="text-lg font-light">
              Create a bundle of all your bills to save more and simplify your home admin with just
              one easy bill to manage. You can also add your council tax and a TV package too!
            </p>
            <div className="flex flex-col items-center gap-8 md:flex-row">
              <LinkButton
                href="https://app.homebox.co.uk/home/setup-account"
                customStyles="mt-0 mb-0 max-w-64"
                target="_blank"
              >
                Explore bundles
              </LinkButton>
              <img src={BundlesLogos} alt="Bundles Logos" className="w-full max-w-96" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
