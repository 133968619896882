import { Link } from 'react-router-dom';

export const ApiRoutes = {
  AUTH_CHECK: '/api/home/auth/check',
  HOME_INVITATION: '/api/home/invitation',
  MESSAGE_ROOMS: (moveId: string) => `/api/home/moves/${moveId}/message-rooms`,
  MESSAGES: (moveId: string, messageRoomId: string) =>
    `/api/home/moves/${moveId}/message-room/${messageRoomId}/messages`,
  MOVE: (moveId: string) => `/api/home/moves/${moveId}`,
  MOVE_STAGES: (moveId: string) => `/api/home/moves/${moveId}/steps`, // In the middle of a migration MOV-529
  PROFILE: '/api/home/me',
  CONFIRM_PASSWORD: '/api/home/confirm-password',
  MAYBE_FORGOT_PASSWORD: '/api/home/maybe-forgot-password',
  CONFIRM_FORGOT_PASSWORD: '/api/home/confirm-forgot-password',
  CREATE_MESSAGE: (moveId: string, messageRoomId: string) =>
    `/api/home/moves/${moveId}/message-room/${messageRoomId}`,
  ONBOARD_USER: (id: string, email: string, leadId: string) =>
    `/api/home/onboard?id=${id}&email=${email}&lead-id=${leadId}`,
  SIGNIN: '/api/home/auth/signin',
  SIGNOUT: '/api/home/auth/signout',
  UPDATE_TASK: (moveId: string, taskId: string, taskType: string) =>
    `/api/home/moves/${moveId}/tasks/${taskId}?taskType=${taskType}`,
};

export enum WizardPaths {
  saleAgreed = 'sale-agreed',
  findAProperty = 'find-a-property',
  finance = 'finance',
  conveyancing = 'conveyancing',
  signContracts = 'sign-contracts',
  exchangeContracts = 'exchange-contracts',
  insurance = 'insurance',
  removals = 'removals',
  energy = 'energy',
  broadband = 'broadband',
  getReady = 'get-ready',
  complete = 'complete',
}

export const ERROR_ID_MAP: any = {
  'BAD-PASSWORD': 'Password criteria not met or passwords do not match.',
  TERMS: 'You must accept the terms and conditions, or terms are not up to date.',
  'USER-EXISTS': (
    <>
      The email address associated with this invitation is already in use.{' '}
      <Link to="/" className="font-semibold">
        Please sign in here
      </Link>
      .<br />
      <br />
      If you have any questions please speak to a Home Runner by starting a{' '}
      <button
        onClick={() => window.LiveChatWidget.call('maximize')}
        className="border-none font-semibold"
        aria-label="Open Live Chat"
      >
        live chat
      </button>
      , emailing{' '}
      <Link to="mailto:hello@home.cc" className="font-semibold">
        hello@home.cc
      </Link>{' '}
      or phoning{' '}
      <Link to="tel:03300433828" className="font-semibold">
        03300 433 828
      </Link>
    </>
  ),
};
