// Images
import downloadImg from '@images/icons/download.svg';

// Utils
import { getAgencyStyling } from '@utils/agencyStyling';
import { convertINameToString } from '@utils/convertINameToString';
import { isAgentParticipant } from '@utils/participantTypeChecks';
import { getFileImage } from '@utils/getFileImage';
import { humanFileSize } from '@utils/humanFileSizes';

export type MessageWithDateTime = Message & {
  dateTime: string;
};

type MessageAttachmentProps = {
  message: MessageWithDateTime;
  moveId: string;
  room: MessageRoom;
};

export const MessageAttachment = ({ message, moveId, room }: MessageAttachmentProps) => {
  const participant = message.participant;

  const isAgent = !!participant ? isAgentParticipant(participant) : false;

  const agentCastParticipant = participant as IAgentParticipant;
  const personCastParticipant = participant as IPersonParticipant;

  const name = !participant
    ? 'You'
    : isAgentParticipant(participant)
      ? convertINameToString(agentCastParticipant?.agency?.agent)
      : convertINameToString(personCastParticipant.person.name);

  if (!message.attachment) return null;

  return (
    <button
      onClick={() =>
        !!message.attachment &&
        window.open(
          `/api/home/moves/${moveId}/message-room/${room.id}/attachment/${message.attachment.id}`,
          '_blank'
        )
      }
      className="flex w-full justify-center border-b border-gray-300 px-3 py-6 transition-all hover:bg-gray-50"
    >
      <div className="grid w-full max-w-lg grid-cols-8">
        {/* ---- FILE TYP ICON ---- */}
        <div className="col-span-1 flex items-start py-2">
          <img
            alt="File type icon"
            src={getFileImage(message.attachment?.type || '')}
            className="w-10"
            aria-hidden="true"
          />
        </div>

        {/* ---- FILE INFO ---- */}
        <div className="col-span-5 flex flex-col items-start text-sm md:text-base">
          <span className="text-base font-medium md:text-lg">{message.attachment?.name}</span>
          <span className="text-gray-500">Shared by {name}</span>
          <span className="text-gray-500">{message.dateTime}</span>
          <span className="text-gray-500">{humanFileSize(message.attachment?.size || 0)}</span>
        </div>

        {/* ---- LOGO / INITIALS ---- */}
        <div className="col-span-1 flex justify-center">
          {isAgent && (
            <div className="flex h-8 w-8 flex-shrink-0 rounded-full md:h-10 md:w-10" aria-hidden>
              <img
                src={getAgencyStyling(agentCastParticipant?.agency.code).images.circleSmall}
                style={{ width: '100%' }}
                alt={`${agentCastParticipant.agency.name} logo`}
                role="presentation"
              />
            </div>
          )}
          {!!personCastParticipant && !isAgent && (
            <span className=" flex h-8 w-8 items-center justify-center rounded-full bg-gray-900 text-sm font-medium uppercase text-white md:h-10  md:w-10">
              {personCastParticipant.person.name.firstName.charAt(0) +
                personCastParticipant.person.name.lastName.charAt(0)}
            </span>
          )}
        </div>

        {/* ---- DOWNLOAD BUTTON ---- */}
        <div className="justify-cente col-span-1 flex items-start">
          <a
            href={`/api/home/moves/${moveId}/message-room/${room.id}/attachment/${message.attachment.id}`}
            download={message.attachment.name}
            className="has-tooltip relative -mt-2 overflow-visible rounded-full px-3 py-2 hover:bg-gray-200"
          >
            <img src={downloadImg} alt="Download file" className="md:min-w-7" />
            <span className="tooltip invisible absolute left-1/2 top-[88px] -mt-8 -translate-x-1/2 rounded bg-primary p-2 text-sm text-white shadow-lg transition-all duration-300">
              Download
            </span>
          </a>
        </div>
      </div>
    </button>
  );
};
