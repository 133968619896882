import SmartHomeFloodlightCam from '@images/products/product_ring-floodlight-cam.webp';
import GoogleNestThermostat from '@images/products/product_google-nest.webp';
import BedroomRemovalPack from '@images/products/product_removal-pack.webp';
import YaleKeylessSmartLock from '@images/products/product_yale-keyless.webp';
import Samsung4KSmartTV from '@images/products/product_samsung-tv.webp';
import RingSpotlightCam from '@images/products/R-01-Ring-spotlight.webp';
import RingChimePro from '@images/products/R-02-Ring-chime-pro.webp';
import RingFloodlightCam from '@images/products/R-03-Ring-floodlight.webp';
import RingVideoDoorbellPro from '@images/products/R-04-Ring-doorbell-pro.webp';

export type Product = {
  title: string;
  offerText?: string;
  text?: string;
  image: string;
  price?: number;
  link: string;
};

type ProductCategory = {
  name: string;
  items: Product[];
};

export const PRODUCT_CATEGORIES: ProductCategory[] = [
  {
    name: 'SMART HOME',
    items: [
      {
        image: SmartHomeFloodlightCam,
        title: 'Ring Floodlight Cam Wired Plus - £219.99',
        text: 'Hardwired for non-stop peace of mind, Ring Floodlight Cam Wired Plus offers reliable outdoor protection and advanced motion detection, day or night.',
        link: 'https://store.home.cc/products/ring-floodlight-cam-wired-plus-black',
      },
      {
        title: 'GOOGLE Nest Thermostat',
        image: GoogleNestThermostat,
        price: 219.99,
        link: 'https://store.home.cc/products/nest-learning-thermostat',
      },
      {
        title: '1-2 Bedroom Removal Pack',
        offerText: 'Exclusive offer',
        image: BedroomRemovalPack,
        price: 46.99,
        link: 'https://store.home.cc/products/1-2-bedroom-removal-pack-25-boxes',
      },
      {
        title: 'Yale Keyless Smart Lock',
        image: YaleKeylessSmartLock,
        price: 99.0,
        link: 'https://store.home.cc/products/yale-keyless-connected-smart-lock-other-smart-security?_pos=2&_sid=0964859ad&_ss=r',
      },
      {
        title: 'Samsung 50" 4K Smart TV',
        image: Samsung4KSmartTV,
        price: 899.0,
        link: 'https://store.home.cc/products/samsung-qe50q80catxxu-tv-127-cm-50-4k-ultra-hd-smart-tv-wi-fi',
      },
    ],
  },
  {
    name: 'SECURITY',
    items: [
      {
        image: RingFloodlightCam,
        title: 'Featured brand - Ring',
        text: "Protect your home & watch over what's important from your phone with Ring",
        link: 'https://store.home.cc/collections/ring?page=1',
      },
      {
        title: 'Ring Spotlight Cam',
        offerText: 'New',
        image: RingSpotlightCam,
        price: 179.99,
        link: 'https://store.home.cc/collections/ring/products/ring-spotlight-cam-battery',
      },
      {
        title: 'Ring Chime Pro',
        image: RingChimePro,
        price: 49.99,
        link: 'https://store.home.cc/collections/ring/products/ring-chime-pro-2nd-gen',
      },
      {
        title: 'Ring Floodlight Cam',
        offerText: 'New',
        image: RingFloodlightCam,
        price: 219.99,
        link: 'https://store.home.cc/collections/ring/products/ring-floodlight-cam-wired-plus-black',
      },
      {
        title: 'Ring Video Doorbell Pro',
        image: RingVideoDoorbellPro,
        price: 219.99,
        link: 'https://store.home.cc/collections/ring/products/video-doorbell-pro-2-hardwired',
      },
    ],
  },
];
