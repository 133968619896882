// Global
import { Tooltip } from 'react-tooltip';

// Images
import { CheckboxEmpty, CheckboxTick, Dash, TickIsolated } from '@images/icons';

// Types
import { Task } from '@features/moveStages/moveStages.types';

// Local
import { ManualTask } from './ManualTask';
import { AutoTask } from './AutoTask';
import { InProgress } from './InProgressAnimation';

export const getIcon = (status: string, manualTask?: boolean) => {
  switch (status) {
    case 'COMPLETED':
      return manualTask ? (
        <CheckboxTick
          className="ml-[5px] mt-[-7px] w-[25px] fill-white md:fill-primary"
          aria-label="Task Completed"
        />
      ) : (
        <TickIsolated className="w-[22px] fill-white md:fill-primary" aria-label="Task Completed" />
      );
    case 'IN_PROGRESS':
      return <InProgress />;
    case 'NOT_STARTED':
    default:
      return manualTask ? (
        <CheckboxEmpty
          className="w-5 fill-gray-200 md:fill-gray-500"
          aria-label="Task Not Started"
        />
      ) : (
        <Dash className="w-5 fill-gray-200 md:fill-gray-500" aria-label="Task Not Started" />
      );
  }
};

type TaskListProps = {
  tasks: Task[];
  moveId: string;
  title?: string;
};

export const TaskList = ({ tasks, moveId, title }: TaskListProps) => (
  <div className="flex w-full flex-col gap-5 p-4 md:p-6">
    {title && <h2 className="text-4xl font-medium text-white md:text-primary">{title}</h2>}
    <div className="flex flex-col gap-5">
      {tasks.map(task => (
        <div key={task.name} className="flex">
          {task.tracking === 'MANUAL' ? (
            <ManualTask {...task} moveId={moveId} />
          ) : (
            <AutoTask {...task} />
          )}
        </div>
      ))}
    </div>
    <Tooltip id="task-tooltip" />
  </div>
);
