// Components
import { LinkButton } from '@components/Button';

// Images
import DefaultImage from '@images/default_image_05-2024.webp';
import { Family, TickIsolated } from '@images/icons';
import HomeCircleSmall from '@images/home_circle_small.png';

// Redux
import { Move } from '@features/move/move.types';

// Utils
import { convertIPriceToString } from '@utils/convertIPriceToString';
import { convertPafToSingleLine } from '@utils/convertPaf';
import { isAgentParticipant } from '@utils/participantTypeChecks';
import { isBuyer } from '@utils/checkBuyerSeller';
import { getAgencyStyling } from '@utils/agencyStyling';
import { isMoveActive } from '@utils/checkMoveStatus';

export const MoveTiles = ({ move }: { move: Move }) => {
  const commonTileStyles =
    'bg-white rounded-[20px] shadow-homeLarge overflow-hidden flex flex-col max-w-xl w-full mx-auto h-full';
  const propertyImage = move.property.images.length > 0 ? move.property.images[0] : DefaultImage;

  const filteredParticipants = move.participants.filter(p =>
    isAgentParticipant(p)
  ) as IAgentParticipant[];

  const images =
    filteredParticipants.map(p => [
      getAgencyStyling(p.agency.code).images.circleSmall,
      p.agency.name,
    ]) || [];

  const isMoving = isMoveActive(move.status);

  const teamText = isMoving ? 'Move Team' : 'Home Team';

  return (
    <div className="my-28 w-full">
      <div className="mx-auto grid max-w-7xl grid-cols-1 items-center justify-center gap-10 px-10 lg:grid-cols-2">
        <div className={commonTileStyles}>
          <div
            className="w-full bg-cover bg-center bg-no-repeat pb-[60%]"
            style={{ backgroundImage: `url(${propertyImage})` }}
          />
          <div className="flex flex-col gap-5 p-6 md:p-10">
            <h2 className="text-3xl font-medium leading-loose md:text-4xl">
              {!isMoving && !isBuyer(move.type) ? 'Recently sold' : 'My Home'}
            </h2>
            <div className="flex items-center gap-3">
              {isMoving && (
                <span className="block rounded-full bg-primary px-3 py-1.5 text-sm font-medium text-white">
                  {isBuyer(move.type) ? 'Buying' : 'Selling'}
                </span>
              )}
              <span className="text-xl font-medium">{convertPafToSingleLine(move.address)}</span>
            </div>
            {isMoving && (
              <h2 className="flex gap-5 text-center font-medium">
                <span className="font-body text-2xl">
                  {convertIPriceToString(move.property.price)}
                </span>
                <span className="flex items-center gap-2 text-xl">
                  <TickIsolated className="h-[19px] fill-primary" /> Sale agreed
                </span>
              </h2>
            )}
          </div>
        </div>
        <div
          className={`${commonTileStyles} items-start justify-end gap-4 px-7 py-8 md:gap-6 md:px-10 md:py-12`}
        >
          <Family className="h-32 fill-primary md:h-36" />
          <h2 className="text-3xl font-medium leading-relaxed md:text-4xl md:leading-relaxed">
            Everyone you need to speak to
            <br className="hidden md:block" /> can be found in your {teamText}
          </h2>
          {images.length > 0 && (
            <div className="mb-6 mt-8 flex pl-2" aria-hidden>
              <img src={HomeCircleSmall} alt="Home logo" className="z-50 -ml-2 h-12 w-12" />
              {images.map(([image, name], index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${name} logo`}
                  className="-ml-2 h-12 w-12"
                  style={{ zIndex: 49 - index }}
                />
              ))}
            </div>
          )}
          <LinkButton href="/your-team" customStyles="mt-0 mb-0">
            View My {teamText}
          </LinkButton>
        </div>
      </div>
    </div>
  );
};
