// Global
import Modal from 'react-modal';
import { useSelector } from 'react-redux';

// Images
import HomeXCoadjute from '@images/home_x_coadjute.webp';

// Redux
import { selectMove } from '@features/move/move';

// Utils
import { isBuyer } from '@utils/checkBuyerSeller';
import { Agencies } from '@utils/agencyStyling';

// Context
import { useModals } from '@contexts/modalContext';

export const HomeCoadjuteModal = () => {
  const { value: move } = useSelector(selectMove);
  const { closeModal } = useModals();

  const broker = move?.participants.filter(
    participant => participant.type === 'BROKER'
  )[0] as IAgentParticipant;
  const agent = move?.participants.filter(
    participant => participant.type === 'AGENT'
  )[0] as IAgentParticipant;
  const conveyancer = move?.participants.filter(
    participant => participant.type === 'CONVEYANCER'
  )[0] as IAgentParticipant;

  const brokerText = !isBuyer(move.type) ? (
    ' '
  ) : !!broker ? (
    <>
      , your broker <b>{broker.agency.name}</b>{' '}
    </>
  ) : (
    ', your broker'
  );
  const agentText =
    agent && agent.agency.code !== Agencies.DEFAULT ? (
      <>
        agent <b>{agent.agency.name}</b>
      </>
    ) : (
      'agent'
    );
  const conveyancerText = conveyancer ? (
    <>
      conveyancer <b>{conveyancer.agency.name}</b>
    </>
  ) : (
    'conveyancer'
  );

  return (
    <Modal
      onRequestClose={() => closeModal('coadjuteXHome')}
      shouldCloseOnOverlayClick
      isOpen
      ariaHideApp={false}
      aria={{
        labelledby: 'home-x-coadjute',
      }}
      style={{
        overlay: {
          zIndex: 9000,
          height: '100%',
          overflow: 'hidden auto',
          textAlign: 'center',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          maxHeight: '100%',
          overflowY: 'auto',
          transform: 'translate(-50%, -50%)',
          padding: '0',
          border: 'none',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          pointerEvents: 'none',
        },
      }}
    >
      <div className="h-full">
        <div
          className="my-10 flex w-full max-w-lg flex-col items-center justify-center rounded-lg bg-white shadow-homeLarge shadow-[rgba(0,0,0,0.35)]"
          aria-labelledby="home-x-coadjute"
        >
          <div className="flex w-full items-center justify-between border-b border-gray-200 py-4 pl-11 pr-3">
            <span>How it works</span>
            <button
              className="cursor-pointer p-3 text-2xl font-semibold"
              onClick={() => closeModal('coadjuteXHome')}
            >
              X
            </button>
          </div>

          <div className="flex flex-col items-center justify-center gap-8 px-9 pb-12 pt-11">
            <img src={HomeXCoadjute} alt="Home x Coadjute" className="w-full max-w-[302px]" />

            <p className="text-center text-lg">
              Home connects you to your {agentText}
              {brokerText} and your {conveyancerText}.
            </p>

            <div className="flex flex-col items-center gap-2">
              <h2 className="m-0 text-center font-body text-2xl font-medium">Real time updates</h2>
              <p className="text-center text-lg">
                This is possible because we are connected to the <b>Coadjute</b> network; a secure
                digital network that allows businesses to communicate real time updates
              </p>
            </div>

            <div className="flex flex-col items-center gap-2">
              <h2 className=" m-0 text-center font-body text-2xl font-medium">
                Secure and private
              </h2>
              <p className=" text-center text-lg">
                Data sent to, or provided from people in your Move Team remains private and is not
                shared with anyone else, keeping your move safe, secure and speedy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
