import { Chat } from '@images/icons';
import { convertINameToString } from '@utils/convertINameToString';

type ParticipantInfoContent = {
  about: {
    title: string;
    content: string;
  };
  questions: {
    title: string;
    content: { title: string; content: string }[];
  };
};

const CONVEYANCER_CONTENT = {
  about: {
    title: 'What is a Conveyancer?',
    content:
      'A Conveyancer is a specialist lawyer who deals with the legal aspects of buying and selling property in the UK. Their role is crucial in ensuring that property transactions are conducted smoothly and legally. They handle tasks such as conducting property searches, preparing and reviewing contracts, dealing with the Land Registry, and transferring funds.',
  },
  questions: {
    title: 'What should I ask my Conveyancer?',
    content: [
      {
        title: 'About Property Searches:',
        content:
          'Can you explain the different types of property searches and which ones are necessary for my transaction?',
      },
      {
        title: 'Regarding Contracts:',
        content: 'What are the key elements I should be aware of in the property contract?',
      },
      {
        title: 'Land Registry Matters:',
        content: 'How do you handle the registration process with the Land Registry?',
      },
      {
        title: 'Financial Transactions:',
        content:
          'What is the process for handling the transfer of funds for the property purchase?',
      },
    ],
  },
};
const BROKER_CONTENT = {
  about: {
    title: 'What is a Broker?',
    content:
      'When working with a mortgage broker, asking the right questions is crucial to ensure that you understand the mortgage process and find the best loan for your needs. Here are four great questions to ask a mortgage broker:',
  },
  questions: {
    title: 'What should I ask my Broker?',
    content: [
      {
        title: 'Types of Mortgages?',
        content:
          'What are the different types of mortgage available that best suit my financial goals?',
      },
      {
        title: 'What is the interest rate and APR?',
        content: 'What is the total cost of the mortgage including any fees?',
      },
      {
        title: 'Qualification Requirements',
        content:
          'Are there any credit score requirements connected to this loan, how can I increase my eligibility for a better interest rates?',
      },
      {
        title: 'Expected Timelines',
        content:
          'What is the expected timeline to get to mortgage offer with your recommended provider?',
      },
    ],
  },
};

const ESTATE_AGENT_CONTENT = {
  about: {
    title: 'What is an Estate Agent?',
    content:
      'An Estate Agent in the UK is a professional involved in the selling, renting, and management of properties. They act on behalf of sellers to market their properties, conduct viewings, negotiate offers, and facilitate the sales process. Estate agents also assist buyers and tenants in finding suitable properties.',
  },
  questions: {
    title: 'What should I ask my Estate Agent?',
    content: [
      {
        title: 'Keeping up to date',
        content: 'How are you going to keep me up to date with the progress?',
      },
      {
        title: 'Understanding the Process',
        content: 'How long would i expect this process to last?',
      },
      {
        title: 'Understanding Costs',
        content:
          'What are your commission rates and are there any additional fees for your services?',
      },
      {
        title: 'Selling your Property',
        content: 'Are you still confident in the buyer?',
      },
    ],
  },
};

const CONTENT = {
  CONVEYANCER: CONVEYANCER_CONTENT,
  BROKER: BROKER_CONTENT,
  AGENT: ESTATE_AGENT_CONTENT,
};

type ParticipantInfoProps = {
  agents: IAgentParticipant[];
  showStartConversation?: boolean;
  persons?: IPersonParticipant[];
};

export const ParticipantInfo = ({
  agents,
  showStartConversation = false,
  persons = [],
}: ParticipantInfoProps) => {
  if (agents.length === 0) return null; // Safe guard

  const participantContent: ParticipantInfoContent =
    CONTENT[agents[0].type as keyof typeof CONTENT];

  const renderIntroText = () => {
    const agentNames = agents.map(agent => convertINameToString(agent.agency.agent));
    const personNames = persons.map(person => convertINameToString(person.person.name));
    const allNames = [...agentNames, ...personNames];

    const namesString = allNames.join(', ').replace(/, ([^,]*)$/, ' & $1');

    if (allNames.length > 1) return `This conversation is between you, ${namesString}.`;

    return `This conversation is between you and ${namesString}.`;
  };

  return (
    <div className="mx-auto mb-10 flex w-full max-w-3xl flex-col gap-6 rounded-xl border  border-gray-200 p-7 text-primary shadow-[rgba(0,0,0,0.05)]">
      <section className="flex flex-col items-center gap-4">
        <Chat className="h-11 w-11 fill-primary" />
        <p className="max-w-md text-center font-heading text-2xl font-medium">
          {renderIntroText()}
        </p>
        <p className="max-w-md text-center font-heading text-2xl font-medium">
          {showStartConversation && 'Start your Conversation here.'}
        </p>
      </section>
      {agents.length === 1 && (
        <>
          <section className="flex flex-col gap-4">
            <h4 className="text-2xl font-medium">{participantContent.about.title}</h4>
            <p className="text-base">{participantContent.about.content}</p>
          </section>
          <section className="flex flex-col gap-4">
            <h4 className="mt-3 text-2xl font-medium">{participantContent.questions.title}</h4>
            <ul className="flex flex-col gap-3 text-base">
              {participantContent.questions.content.map(({ title, content }) => (
                <li key={title}>
                  <b>{title}</b> {content}
                </li>
              ))}
            </ul>
          </section>
        </>
      )}
    </div>
  );
};
