// Global
import { useState } from 'react';
import { AxiosError } from 'axios';

// Utils
import axios from '@utils/customAxios';

type ServerError = {
  error: string;
};

const defaultSettings = {
  isSending: false,
  isSuccess: false,
  serverError: '',
  statusCode: null,
};

export const usePost = () => {
  const [isSending, setIsSending] = useState(defaultSettings.isSending);
  const [isSuccess, setIsSuccess] = useState(defaultSettings.isSuccess);
  const [serverError, setServerError] = useState(defaultSettings.serverError);
  const [statusCode, setStatusCode] = useState<number | null>(defaultSettings.statusCode);

  const postData = async (url: string, data: any, headers?: any) => {
    // Reset defaults
    setIsSuccess(defaultSettings.isSuccess);
    setServerError(defaultSettings.serverError);
    setStatusCode(defaultSettings.statusCode);

    setIsSending(true);

    try {
      const response = await axios.post(url, data, headers);

      setIsSending(false);
      setIsSuccess(true);
      setStatusCode(response.status);

      return response;
    } catch (err) {
      const error = err as AxiosError<ServerError>;

      setStatusCode(error.response?.status || 500);
      setServerError(error.response?.data.error || 'Something went wrong');
    } finally {
      setIsSending(false);
    }
  };

  return { postData, isSending, serverError, isSuccess, statusCode };
};
