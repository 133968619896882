// Global
import { useSelector } from 'react-redux';

// Hooks
import { useMediaQuery } from '@hooks/useMediaQuery';

// Redux
import { selectMove } from '@features/move/move';

// Local
import { MessageAttachment, MessageWithDateTime } from './FilesAttachment';
import { messagesFixedHeights } from '../Common/sizes';

type FileStreamProps = {
  room: MessageRoom;
  messages: MessagesByDate[];
  height: number;
};

export const Files = ({ room, messages, height }: FileStreamProps) => {
  const { IS_TABLET } = useMediaQuery();
  const { value: move } = useSelector(selectMove);

  // Filter messages so we only have ones with an attachment
  const messagesWithAttachments: MessageWithDateTime[] =
    messages?.flatMap(day =>
      day.messages
        .filter((message: any) => !!message.attachment)
        .map((message: any) => ({
          ...message,
          dateTime: `${day.date} ${message.time}`,
        }))
    ) || [];

  return (
    <div
      className="relative z-50 flex w-full flex-col justify-start gap-10 overflow-y-scroll bg-white pt-10"
      style={{
        scrollbarColor: 'rgba(0,0,0,0.3) white',
        height: IS_TABLET
          ? `calc(${height}px - ${messagesFixedHeights.SITE_HEADER + messagesFixedHeights.CHAT_HEADER_DESKTOP}px)`
          : `calc(${height}px - ${messagesFixedHeights.CHAT_HEADER_MOBILE + messagesFixedHeights.FORM}px)`,
      }}
    >
      <h3 className="text-center text-2xl">Shared Files</h3>
      {messagesWithAttachments.length > 0 ? (
        <div className="flex flex-col-reverse">
          {messagesWithAttachments.map((message: any) => (
            <MessageAttachment key={message.id} message={message} moveId={move.id} room={room} />
          ))}
        </div>
      ) : (
        <p className="my-5 w-full text-center">There are no files shared.</p>
      )}
    </div>
  );
};
