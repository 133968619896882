// Global
import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';

// Constants
import { ApiRoutes } from '@constants/index';

// Hooks
import { usePatch } from '@hooks/usePatch';
import { useToast } from '@hooks/useToast';

// Redux
import { Task } from '@features/moveStages/moveStages.types';
import { fetchMove } from '@features/move/move.thunk';
import { fetchMoveStages } from '@features/moveStages/moveStages.thunks';

// Local
import { getIcon } from '.';
import { AppThunkDispatch } from 'src/App';

export const ManualTask = ({ id, uiName, status, moveId, name }: Task & { moveId: string }) => {
  const [taskStatus, setTaskStatus] = useState('');
  const { postData, isSending } = usePatch(ApiRoutes.UPDATE_TASK(moveId, id, name));
  const { dispatchErrorToast } = useToast();
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    setTaskStatus(status);
  }, [status]);

  const handleUpdateTask = async () => {
    const initialStatus = taskStatus;

    try {
      setTaskStatus('IN_PROGRESS');

      const response = await postData({
        status: taskStatus === 'COMPLETED' ? 'NOT_STARTED' : 'COMPLETED',
      });

      if (!!response) {
        dispatch(fetchMoveStages(moveId));

        /**
         * If name is HOME_APP_REMOVALS, fetch the move to update the move status
         * Hard coding this here in the event the "Moving -> Living" logic changes as
         * it's the only instance of a manual task needing to refresh to move data
         */
        if (name === 'HOME_APP_REMOVALS') {
          dispatch(fetchMove(moveId));
        }

        setTaskStatus(initialStatus === 'COMPLETED' ? 'NOT_STARTED' : 'COMPLETED');
      } else {
        setTaskStatus(initialStatus);
      }
    } catch (error) {
      Sentry.captureException(error);
      setTaskStatus(initialStatus);
      dispatchErrorToast(`Something went wrong updating the task "${uiName}"`);
    }
  };

  return (
    <button
      disabled={isSending}
      className="flex cursor-pointer select-none items-center gap-2 disabled:cursor-default"
      onClick={() => handleUpdateTask()}
      aria-label={`Mark ${uiName} as ${taskStatus === 'COMPLETED' ? 'not completed' : 'complete'}`}
      aria-pressed={taskStatus === 'COMPLETED'}
    >
      <span className="flex w-10 items-center justify-center">{getIcon(taskStatus, true)}</span>
      <span
        className={`${
          taskStatus === 'NOT_STARTED'
            ? 'text-gray-200 md:text-gray-500'
            : 'text-white md:text-primary'
        }`}
      >
        {uiName}
      </span>
    </button>
  );
};
