import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'src/store';
import { resetAll } from '@features/common';

import { fetchMessageRooms } from './messageRooms.thunks';
import { MessageRoomsState } from './messageRooms.types';
import { isAgentParticipant, isPersonParticipant } from '@utils/participantTypeChecks';
import { calculateTimePassed } from '@utils/calculateTimePassed';
import { createRoomName } from '@utils/createRoomName';

const initialState: MessageRoomsState = {
  value: {
    rooms: [],
  },
  isFetching: false,
  fetchComplete: false,
  errorOccurred: false,
};

export const messageRoomsSlice = createSlice({
  name: 'messageRooms',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchMessageRooms.pending.type, (state: MessageRoomsState) => {
      state.isFetching = true;
      state.errorOccurred = false;
    });
    builder.addCase(fetchMessageRooms.fulfilled.type, (state: MessageRoomsState, action: any) => {
      state.isFetching = false;
      state.fetchComplete = true;
      state.errorOccurred = false;

      state.value = action.payload;
    });
    builder.addCase(fetchMessageRooms.rejected.type, (state: MessageRoomsState) => {
      state.isFetching = false;
      state.errorOccurred = true;
    });
    builder.addCase(resetAll, () => initialState);
  },
});

const getRooms = (state: RootState) => state.messageRooms.value.rooms;

const getParticipants = (state: RootState) => state.move.value.participants;

export const selectMessageRooms = createSelector(
  [getRooms, getParticipants],
  (rooms, participants) => {
    const mapIdToParticipant = (id: string): IAgentParticipant | IPersonParticipant | null => {
      const participant = participants.find(
        (participant: IParticipant) => participant.type === id || participant.id === id
      );

      if (!participant) return null;

      if ('person' in participant) {
        return participant as IPersonParticipant;
      } else {
        return participant as IAgentParticipant;
      }
    };

    return rooms
      .filter(room => {
        const validParticipants = room.who
          .map(who => mapIdToParticipant(who))
          .filter(participant => participant !== null) as (
          | IPersonParticipant
          | IAgentParticipant
        )[];
        return validParticipants.length === room.who.length;
      })
      .map(room => {
        const agents = room.who
          .map(id => mapIdToParticipant(id))
          .filter(
            participant => participant !== null && isAgentParticipant(participant)
          ) as IAgentParticipant[];

        const persons = room.who
          .map(id => mapIdToParticipant(id))
          .filter(
            participant => participant !== null && isPersonParticipant(participant)
          ) as IPersonParticipant[];

        return {
          ...room,
          formattedLastMessage: !!room.lastMessageTime
            ? calculateTimePassed(room.lastMessageTime)
            : '',
          formattedRoomName: createRoomName(agents, persons),
          agentParticipants: agents,
          personParticipants: persons,
        };
      })
      .sort((a, b) => {
        const aDate = new Date(a.lastMessageTime || 0);
        const bDate = new Date(b.lastMessageTime || 0);
        return bDate.getTime() - aDate.getTime();
      });
  }
);

export const selectMessageRoomsState = (state: RootState) => state.messageRooms;

export const selectMessageRoom = (roomId: string) => (state: RootState) => {
  return state.messageRooms.value.rooms.find((room: any) => room.id === roomId);
};

export const messageRoomsReducer = messageRoomsSlice.reducer;
