import { ApiRoutes } from '@constants/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@utils/customAxios';

export const fetchMessageRooms = createAsyncThunk(
  'messages/fetchingRooms',
  async (moveId: string) =>
    axios
      .get(ApiRoutes.MESSAGE_ROOMS(moveId), {
        withCredentials: true,
      })
      .then(response => response.data)
);
