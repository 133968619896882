import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiRoutes } from '@constants/index';
import axios from '@utils/customAxios';

export const fetchMoveStages = createAsyncThunk(
  'moveStages/fetchingStages',
  async (moveId: string) =>
    axios
      .get(ApiRoutes.MOVE_STAGES(moveId), {
        withCredentials: true,
      })
      .then(response => response.data)
);
