import { useState } from 'react';
import axios from '@utils/customAxios';

export const usePatch = (url: string, headers?: any) => {
  const [isSending, setIsSending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const postData = async (data: any) => {
    setIsSending(true);

    try {
      const response = await axios.patch(url, data, headers || {});

      setIsSending(false);
      setIsSuccess(true);

      return response;
    } finally {
      setIsSending(false);
    }
  };

  return { postData, isSending, isSuccess };
};
