import { Broadband, Bulb, Cleaning, Insurance } from '@images/icons';
import BundlesLogos from '@images/bundles-logos.png';

const SmallItem = ({ title, description, link, linkText, newTab = true }: any) => {
  return (
    <div className="relative flex flex-col justify-center gap-4 border border-gray-200 bg-white py-10 shadow-homeLarge md:py-5">
      <div className="flex flex-col items-center gap-5 px-4">
        <h3 className="flex items-center justify-center gap-1.5 text-center text-xl font-medium">
          {title}
        </h3>
        <p className="text-center">{description}</p>
        <a
          href={link}
          target={newTab ? '_blank' : '_self'}
          className="rounded-full bg-primary px-5 py-2 text-sm text-white"
          rel="noreferrer"
        >
          {linkText}
        </a>
      </div>
    </div>
  );
};

const FeaturedItem = ({
  heroSection,
  title,
  description,
  link,
  linkText,
  secondarySection,
}: any) => {
  return (
    <div className="relative flex flex-col gap-4 border border-gray-200 bg-white pb-2 shadow-homeLarge md:pb-5">
      <div className=" flex h-full flex-col justify-center gap-7 px-4 py-8 md:gap-10 md:py-12">
        <div>{heroSection}</div>
        <div className="flex flex-col items-center justify-center gap-5">
          <h3 className="text-center text-2xl font-medium">{title}</h3>
          <p className="text-center">{description}</p>
        </div>
        <div className="flex w-full justify-center">{secondarySection}</div>
        <div className="flex w-full items-center justify-center gap-2">
          <a
            href={link}
            target="_blank"
            className="rounded-full bg-primary px-5 py-2 text-sm text-white"
            rel="noreferrer"
          >
            {linkText}
          </a>
        </div>
      </div>
    </div>
  );
};

type StoreProps = {
  isMoving: boolean;
  isBuying: boolean;
};

export const Services = ({ isMoving, isBuying }: StoreProps) => {
  return (
    <div className="mx-auto grid max-w-md grid-cols-1 gap-5 md:max-w-max lg:grid-cols-2">
      <FeaturedItem
        heroSection={
          <div className="flex items-center justify-center gap-3 py-5">
            <Bulb className="h-20 fill-home" />
            <Broadband className="h-20 fill-home" />
            <Cleaning className="h-20 fill-home" />
            <Insurance className="h-20 fill-home" />
          </div>
        }
        secondarySection={
          <img src={BundlesLogos} alt="Bundles Logos" className="w-full max-w-96" />
        }
        title="You could save time and money with a Home bills bundle"
        description="Create a bundle of all your bills to save more and simplify your home admin with just one easy bill to manage. You can also add your council tax and a TV package too!"
        link="https://app.homebox.co.uk/home/setup-account"
        linkText="Explore your bills bundle"
      />
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <SmallItem
          title={
            <>
              <Insurance className="h-6 fill-home" />
              {`Protect your move`}
            </>
          }
          description={
            isBuying
              ? 'Cover your legal, survey and mortgage costs should your purchase fall through'
              : 'Cover your legal costs should your sale fall through'
          }
          link="https://home.rhinohomeprotect.com/quote/"
          linkText={`Home ${isBuying ? 'Buyers' : 'Sellers'} Protection`}
        />
        <SmallItem
          title={
            <>
              {`Set up`}
              <Insurance className="h-6 fill-home" />
              {`home insurance`}
            </>
          }
          description="Find the perfect tariff for your home and switch today"
          link="https://homecc.quotezone.co.uk/home/index.php?id=5624248ac079e49fdd6736ce310c25e8"
          linkText="Home insurance"
        />
        <SmallItem
          title={
            <>
              {`Save on your`}
              <Bulb className="h-7 fill-home" />
              {`energy bills`}
            </>
          }
          description="Find the perfect tariff for your home and switch today"
          link={isMoving ? '/wizard/energy' : '/services/energy'}
          newTab={false}
          linkText="Explore energy tariffs"
        />
        <SmallItem
          title={
            <>
              {`Set up`}
              <Broadband className="h-7 fill-home" />
              {`broadband`}
            </>
          }
          description="Find the right broadband deal for you and get set up."
          link={isMoving ? '/wizard/broadband' : '/services/broadband'}
          newTab={false}
          linkText="Explore broadband"
        />
      </div>
    </div>
  );
};

export const NonNetworkServices = () => {
  return (
    <div className="mx-auto grid max-w-md grid-cols-1 gap-5 md:max-w-max lg:grid-cols-2">
      <FeaturedItem
        heroSection={
          <div className="flex items-center justify-center gap-3 py-5">
            <Bulb className="h-20 fill-home" />
            <Broadband className="h-20 fill-home" />
            <Cleaning className="h-20 fill-home" />
            <Insurance className="h-20 fill-home" />
          </div>
        }
        secondarySection={
          <img src={BundlesLogos} alt="Bundles Logos" className="w-full max-w-96" />
        }
        title="Save time and money with a Home bills bundle"
        description="Create a bundle of all your bills to save more and simplify your home admin with just one easy bill to manage. You can also add your council tax and a TV package too!"
        link="https://app.homebox.co.uk/home/setup-account"
        linkText="Explore your bills bundle"
      />

      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <SmallItem
          title={
            <>
              {`Save on your`}
              <Bulb className="h-7 fill-home" />
              {`energy bills`}
            </>
          }
          description="Find the perfect tariff for your home and switch today"
          link="/services/energy"
          newTab={false}
          linkText="Explore energy tariffs"
        />
        <SmallItem
          title={
            <>
              {`Set up`}
              <Broadband className="h-7 fill-home" />
              {`broadband`}
            </>
          }
          description="Find the right broadband deal for you and get set up."
          link="/services/broadband"
          newTab={false}
          linkText="Explore broadband"
        />
        <SmallItem
          title={
            <>
              {`Save on`}
              <Insurance className="h-6 fill-home" />
              {`home insurance`}
            </>
          }
          description="Compare quotes from over 50 UK insurance providers and see how much you could save."
          link="https://homecc.quotezone.co.uk/home/index.php?id=5624248ac079e49fdd6736ce310c25e8"
          linkText="Explore home insurance"
        />
        <SmallItem
          title={
            <>
              {`Stress free`}
              <Cleaning className="h-6 fill-home" />
              {`house cleans`}
            </>
          }
          description="Hassle-free cleaning that gets your house sparkling."
          link="https://home.cc/services/cleaning"
          linkText="Explore cleaning services"
        />
      </div>
    </div>
  );
};
