// Contexts
import { useModals } from '@contexts/modalContext';

// Images
import { Alert } from '@images/icons';

// Local
import { ReactComponent as YourConnected } from './icons/your-connected.svg';

export type ConnectedPillProps = {
  image: string;
  companyName: string;
};

export const ConnectedPill = ({ companyName, image }: ConnectedPillProps) => {
  const modals = useModals();

  return (
    <div
      className="mt-5 flex h-12 w-full max-w-[331px] items-center gap-3 overflow-hidden rounded-full bg-white pl-1 pr-4 shadow-md"
      aria-label={`You are connected to ${companyName}`}
    >
      <div
        className="h-full w-[117px] rounded-br-full rounded-tr-full bg-contain bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${image})` }}
        aria-hidden
      />
      <YourConnected aria-hidden />
      <Alert
        className="h-5 w-5 cursor-pointer fill-primary"
        aria-label="Show Coadjute Information"
        onClick={() => modals.openModal('coadjuteXHome')}
      />
    </div>
  );
};
