import { ReactNode } from 'react';

type BreakoutBoxProps = {
  children: ReactNode;
  transparentmobile?: boolean;
};

export const BreakoutBox = ({ children, transparentmobile = false }: BreakoutBoxProps) => {
  const transparentMobileStyles = transparentmobile
    ? 'bg-transparent md:bg-white p-0 md:py-7 md:px-6'
    : 'bg-white py-7 px-6';
  return (
    <div
      className={`flex w-full max-w-lg flex-col items-center gap-4 rounded-xl md:max-w-full ${transparentMobileStyles}`}
    >
      {children}
    </div>
  );
};
