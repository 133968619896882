import { createSlice } from '@reduxjs/toolkit';

import { resetAll } from '@features/common';

import { OnboardingState } from './onboarding.types';
import { fetchInvitation } from './onboarding.thunk';

const initialState: OnboardingState = {
  value: null,
  isFetching: false,
  fetchComplete: false,
  errorOccurred: false,
};

export const onboardingSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchInvitation.pending.type, (state: OnboardingState) => {
      state.isFetching = true;
      state.errorOccurred = false;
    });
    builder.addCase(fetchInvitation.fulfilled.type, (state: OnboardingState, action: any) => {
      state.isFetching = false;
      state.fetchComplete = true;
      state.errorOccurred = false;

      state.value = action.payload;
    });
    builder.addCase(fetchInvitation.rejected.type, (state: OnboardingState, action: any) => {
      state.fetchComplete = true;
      state.isFetching = false;
      state.errorOccurred = true;
    });
    builder.addCase(resetAll, () => initialState);
  },
});

export const onboardingReducer = onboardingSlice.reducer;
