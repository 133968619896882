// Global
import { useEffect, useRef, useState } from 'react';

// Constants
import { HEADER_HEIGHT } from '@components/Header';

// Images
import { Chat } from '@images/icons';

// Hooks
import { useMediaQuery } from '@hooks/useMediaQuery';

// Types
import { EnhancedMessageRoom } from '@features/messageRooms/messageRooms.types';

// Local
import { Contact } from './Contact';

const SITE_HEADER_HEIGHT = HEADER_HEIGHT + 208;

type SidebarProps = {
  rooms: EnhancedMessageRoom[];
  activeRoomIndex: number;
  handleRoomClick: (i: number) => void;
};

export const Sidebar = ({ rooms, activeRoomIndex, handleRoomClick }: SidebarProps) => {
  const asideRef = useRef<HTMLElement>(null);
  const [isOverflowNeeded, setIsOverflowNeeded] = useState(false);

  const { IS_TABLET } = useMediaQuery();

  useEffect(() => {
    const checkOverflow = () => {
      if (asideRef.current) {
        const aside = asideRef.current;
        const innerDiv = aside.firstChild as HTMLElement;

        const needsOverflow = innerDiv && innerDiv.clientHeight > aside.clientHeight;
        if (needsOverflow !== isOverflowNeeded) {
          // Only update state if different
          setIsOverflowNeeded(needsOverflow);
        }
      }
    };

    checkOverflow();

    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [rooms, activeRoomIndex, isOverflowNeeded]);

  return (
    <aside
      ref={asideRef}
      style={
        IS_TABLET && isOverflowNeeded
          ? {
              maxHeight: `calc(100vh - ${SITE_HEADER_HEIGHT}px)`,
              scrollbarColor: 'rgba(0,0,0,0.3) white',
              overflowY: 'scroll',
            }
          : IS_TABLET
            ? { height: `calc(100vh - ${SITE_HEADER_HEIGHT}px)` }
            : {}
      }
      data-testid="messages-sidebar"
    >
      <div className="flex flex-col pb-24">
        {rooms.length > 0 &&
          rooms.map((room, i) => {
            return (
              <Contact
                key={i}
                active={i === activeRoomIndex}
                onClick={() => handleRoomClick(i)}
                room={room}
              />
            );
          })}

        <div className="mt-4 flex max-w-96 flex-col gap-5 p-7">
          <p>
            <strong>Need a helping hand?</strong> Our team of Home Runners are here to help.
          </p>
          <p
            className="flex cursor-pointer items-center gap-5 underline hover:no-underline"
            onClick={() => window.LiveChatWidget.call('maximize')}
            role="button"
            aria-label="Start a live chat"
            tabIndex={0}
            onKeyDown={e => {
              if (e.key === 'Enter') window.LiveChatWidget.call('maximize');
            }}
          >
            <span className="flex h-[55px] w-[60px] items-center justify-center rounded-[50%] bg-white shadow-md shadow-[rgba(0,0,0,0.15)]">
              <Chat className="h-8 w-8 fill-primary" data-testid="chat-icon" aria-hidden />
            </span>
            <span>Start a live chat</span>
          </p>
        </div>
      </div>
    </aside>
  );
};
