// Components
import { ErrorMessage } from '@components/ErrorMessage';

// Hooks
import { useMediaQuery } from '@hooks/useMediaQuery';

// Redux
import { EnhancedMessageRoom } from '@features/messageRooms/messageRooms.types';

// Local
import { Message } from './Message';
import { messagesFixedHeights } from '../Common/sizes';
import { ParticipantInfo } from './ParticipantInfo';

type MessageStreamProps = {
  room: EnhancedMessageRoom;
  messages: MessagesByDate[];
  moveId: string;
  height: number;
  fetchError?: boolean;
};

export const Messages = ({ messages, room, moveId, height, fetchError }: MessageStreamProps) => {
  const { IS_TABLET } = useMediaQuery();

  return (
    <div className="relative w-full">
      <div
        className="absolute left-0 top-0 z-20 h-14 w-full md:w-[calc(100%-5px)]"
        style={{
          backgroundImage: 'linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1))',
        }}
      />
      <div
        className="relative z-10 flex items-center gap-12 overflow-y-scroll bg-white px-5"
        style={{
          overflowY: 'scroll',
          scrollbarColor: 'rgba(0,0,0,0.3) white',
          flexDirection: 'column-reverse',
          height: `calc(${height}px - ${messagesFixedHeights.CHAT_HEADER_MOBILE + messagesFixedHeights.FORM}px)`,
          ...(IS_TABLET && {
            height: `calc(100vh - ${
              messagesFixedHeights.SITE_HEADER +
              messagesFixedHeights.CHAT_HEADER_DESKTOP +
              messagesFixedHeights.FORM
            }px)`,
          }),
        }}
      >
        <div className="py-10">
          {fetchError ? (
            <div className="max-auto max-w-lg">
              <ErrorMessage includeHomeRunnerContact>
                An error occurred fetching messages.
              </ErrorMessage>
            </div>
          ) : (
            <>
              <ParticipantInfo agents={room.agentParticipants} persons={room.personParticipants} />
              <div className="flex h-auto w-full flex-col items-center justify-center gap-8">
                {messages.map(dayBlock => (
                  <div
                    aria-label="day"
                    key={dayBlock.date}
                    className="flex w-full max-w-[450px] flex-col items-center gap-5"
                  >
                    <span className="rounded-2xl bg-[rgba(17,17,17,0.6)] px-2 py-[1px] text-xs text-white">
                      {dayBlock.date}
                    </span>
                    <div className="flex min-w-full flex-col items-center gap-5">
                      {dayBlock.messages.map(message => (
                        <Message
                          key={message.id}
                          message={message}
                          roomId={room.id}
                          moveId={moveId}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
