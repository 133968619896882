import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { resetAll } from '@features/common';

import { MoveSlice } from './move.types';
import { fetchMove } from './move.thunk';

export const initialMoveState: MoveSlice = {
  value: {
    id: '',
    type: 'BUYER',
    status: '',
    address: {
      street: '',
      postcode: '',
      postTown: '',
    },
    property: {
      price: {
        amount: 0,
        currency: 'GBP',
      },
      images: [],
    },
    participants: [],
  },
  isFetching: false,
  fetchComplete: false,
  errorOccurred: false,
};

const PTO = ['AGENT', 'BROKER', 'CONVEYANCER'];

export const moveSlice = createSlice({
  name: 'move',
  initialState: initialMoveState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchMove.pending.type, (state: MoveSlice) => {
      state.isFetching = true;
      state.errorOccurred = false;
    });
    builder.addCase(fetchMove.fulfilled.type, (state: MoveSlice, action: any) => {
      state.isFetching = false;
      state.fetchComplete = true;
      state.errorOccurred = false;
      state.value = {
        ...action.payload,
        participants: (action.payload.participants as any[]).sort(
          (a, b) => PTO.indexOf(a.type) - PTO.indexOf(b.type)
        ),
      };
    });
    builder.addCase(fetchMove.rejected.type, (state: MoveSlice) => {
      state.isFetching = false;
      state.errorOccurred = true;
    });
    builder.addCase(resetAll, (state: MoveSlice) => {
      state.value = initialMoveState.value;
      state.isFetching = initialMoveState.isFetching;
      state.fetchComplete = false;
    });
  },
});

export const selectMove = (state: RootState) => state.move;

export const selectMoveId = (state: RootState) => state.move.value.id;

export const selectEstateAgent = (state: RootState) =>
  state.move.value.participants.find(p => p.type === 'AGENT');

export const selectIsMoveActive = (state: RootState): boolean => {
  const status = state.move.value.status;
  if (!status) return true; // If status is not set, assume move is active
  if (status === 'COMPLETED' || status === 'ABANDONED') return false;
  if (status === 'IN_PROGRESS' || status === 'CAN_COMPLETE') return true;
  return false; // unknown status
};

export const moveReducer = moveSlice.reducer;
