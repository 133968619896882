import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'src/store';
import { resetAll } from '@features/common';

import { fetchMessages, postMessage } from './messages.thunks';
import { format } from 'date-fns';

type MessageList = {
  id: string;
  messages: MessageApiResponse[];
  status: {
    isFetching: boolean;
    initialFetchComplete?: boolean;
    fetchError?: boolean;
    isPosting: boolean;
    postError?: number | null;
  };
};

export type MessagesState = {
  messageLists: MessageList[];
};

const initialState: MessagesState = {
  messageLists: [],
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    // Fetch Messages
    builder.addCase(fetchMessages.pending.type, (state: MessagesState, action: any) => {
      const existingRoomIndex = state.messageLists.findIndex(
        list => list.id === action.meta.arg.roomId
      );

      if (existingRoomIndex !== -1) {
        state.messageLists[existingRoomIndex].status.isFetching = true;
      } else {
        state.messageLists.push({
          id: action.meta.arg.roomId,
          messages: [],
          status: {
            isFetching: true,
            isPosting: false,
          },
        });
      }
    });
    builder.addCase(fetchMessages.fulfilled.type, (state: MessagesState, action: any) => {
      const existingRoomIndex = state.messageLists.findIndex(
        room => room.id === action.meta.arg.roomId
      );

      if (existingRoomIndex >= 0) {
        state.messageLists[existingRoomIndex].messages = action.payload.data.messages;
        state.messageLists[existingRoomIndex].status.isFetching = false;
        state.messageLists[existingRoomIndex].status.initialFetchComplete = true;
        state.messageLists[existingRoomIndex].status.fetchError = false;

        if (action.payload.newMessageExpected) {
          state.messageLists[existingRoomIndex].status.isPosting = false;
        }
      }
    });
    builder.addCase(fetchMessages.rejected.type, (state: MessagesState, action: any) => {
      const existingRoomIndex = state.messageLists.findIndex(
        room => room.id === action.meta.arg.roomId
      );

      if (existingRoomIndex >= 0) {
        state.messageLists[existingRoomIndex].status.isFetching = false;
        state.messageLists[existingRoomIndex].status.initialFetchComplete = true;
        state.messageLists[existingRoomIndex].status.fetchError = true;
      }
    });

    // Post Message
    builder.addCase(postMessage.pending.type, (state: MessagesState, action: any) => {
      const existingRoomIndex = state.messageLists.findIndex(
        room => room.id === action.meta.arg.roomId
      );

      if (existingRoomIndex >= 0) {
        state.messageLists[existingRoomIndex].status.postError = null;
        state.messageLists[existingRoomIndex].status.isPosting = true;
      }
    });

    builder.addCase(postMessage.rejected.type, (state: MessagesState, action: any) => {
      const existingRoomIndex = state.messageLists.findIndex(
        room => room.id === action.meta.arg.roomId
      );

      const payload = JSON.parse(action.payload);

      if (existingRoomIndex >= 0) {
        state.messageLists[existingRoomIndex].status.isPosting = false;
        state.messageLists[existingRoomIndex].status.postError = payload?.status || 500;
      }
    });

    // Reset
    builder.addCase(resetAll, () => initialState);
  },
});

export const selectMessagesState = (state: RootState) => state.actualMessages;

export const selectRoom = (roomId: string) =>
  createSelector(selectMessagesState, messagesState =>
    messagesState.messageLists.find(room => room.id === roomId)
  );

export const selectMessagesForRoom = (roomId: string) =>
  createSelector(
    [selectRoom(roomId), (state: RootState) => state.move.value.participants],
    (room, participants) => {
      if (!room) return [];

      const readyToSort = [...room.messages].sort(
        (a, b) => new Date(a.at).getTime() - new Date(b.at).getTime()
      );
      const groupedMessages: MessagesByDate[] = [];

      readyToSort.forEach(message => {
        const date = new Date(message.at);
        const formattedDate = format(date, 'MMMM d');
        const formattedTime = format(date, 'h:mm a');

        const ParsedDayBlockOfMessages = groupedMessages.find(
          groupedMessage => groupedMessage.date === formattedDate
        );

        if (ParsedDayBlockOfMessages) {
          ParsedDayBlockOfMessages.messages.push({
            id: message.id,
            text: message.text,
            time: formattedTime,
            who: message.who,
            attachment: message.attachment,
            participant: participants.find(participant => participant.id === message.who) as
              | IAgentParticipant
              | IPersonParticipant,
          });
        } else {
          groupedMessages.push({
            date: formattedDate,
            messages: [
              {
                id: message.id,
                text: message.text,
                time: formattedTime,
                who: message.who,
                attachment: message.attachment,
                participant: participants.find(participant => participant.id === message.who) as
                  | IAgentParticipant
                  | IPersonParticipant,
              },
            ],
          });
        }
      });

      return groupedMessages;
    }
  );

export const messagesReducer = messagesSlice.reducer;
